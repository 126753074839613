<template>
  <div class="app-content">
    <!-- 搜索框功能 -->
    <TableBox />
  </div>
</template>

<script>
import TableBox from '@/components/TableBox.vue'
import DownloadBox from '@/components/DownloadBox.vue'

export default {
  name: 'TableView',
  components: {
    TableBox,
  },
}
</script>
<style lang="less" scoped>
.app-container {
  // position: absolute;
  height: 100%;
  width: 100%;
}
.app-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>
