<template>
  <div class="app-header app-header-mobile">
    <div class="header-webther fl d-flex-ver d-pointer"></div>
    <div class="header-setting fr mobile-setting">
      <el-tooltip
        v-if="isShowButton"
        content="上传发票"
        placement="left"
        :visible="isShowButton"
      >
        <el-icon
          v-if="isShowButton"
          @click="$store.state.isShowUpload = true"
          class="icon-handle d-inline f36 ml10"
        ><Plus /></el-icon>
      </el-tooltip>
      <!-- 添加配置管理按钮 -->
      <el-tooltip
        content="配置管理"
        placement="left"
      >
        <el-icon
          @click="showConfigDialog"
          class="icon-handle d-inline f36 ml10"
        ><Setting /></el-icon>
      </el-tooltip>
    </div>
    <div id="aMap" class="d-hide"></div>
    <ConfigManagerDialog v-model:visible="configDialogVisible" />
  </div>
</template>

<script>
import { Plus, Setting } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import ConfigManagerDialog from './ConfigManagerDialog.vue'
import { ref } from 'vue'

export default {
  name: 'HeaderBox',
  props: {},
  components: { Plus, Setting, ConfigManagerDialog },
  setup() {
    const router = useRouter()
    const configDialogVisible = ref(false)

    const goToConfigPage = () => {
      router.push('/config')
    }

    const showConfigDialog = () => {
      configDialogVisible.value = true
    }

    return {
      goToConfigPage,
      configDialogVisible,
      showConfigDialog
    }
  },
  data() {
    //这里存放数据
    return {
      isMobile: window.innerWidth <= 768,
      isShowTooltip: true,
      isShowButton: true,
    }
  },

  created() {},

  mounted() {
    window.addEventListener('resize', this.updateIsMobile)
  },
  computed: {},
  watch: {
    '$store.state.searchButton': {
      handler(newValue, oldValue) {
        if (oldValue !== undefined && this.isMobile) {
          this.isShowTooltip = false
          this.isShowButton = false
        }
      },
      deep: true,
      immediate: true,
    },
    '$store.state.isHasDialog': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.isShowButton = false
        } else {
          this.isShowButton = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  //方法集合
  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768 // Adjust the breakpoint as needed
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroyed() {},
  beforeUnmount() {
    window.removeEventListener('resize', this.updateIsMobile)
  },
}
</script>
<style lang="less" scoped>
//@import url();
.app-header {
  overflow: hidden;
  max-height: 64px;
  min-height: 50px;
  padding: 20px 50px;
  color: #fff;
  .header-webther {
    .super {
      // vertical-align: super;
      font-size: 12px;
      color: #ddd;
      margin-top: -3px;
    }
    .icon {
      color: #fff;
    }
    .wea {
      font-size: 20px;
      margin-left: 8px;
      margin-right: 6px;
    }
  }
}
.header-setting {
  .icon-handle {
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.4;
    &:hover {
      transform: rotate(90deg);
      opacity: 0.9;
    }
    margin: 0px 10px;
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .mobile-setting {
    position: fixed;
    bottom: 25px; /* 调整按钮距离底部的距离 */
    right: 15px;
    display: flex;
    flex-direction: column;
    .icon-handle {
      font-size: 45px;
    }
    // align-items: flex-start;
  }
  .app-header-mobile {
    overflow: hidden;
    max-height: 15px;
    min-height: 15px;
    padding: 20px 15px;
  }
}
</style>
