import axios from 'axios'
import store from '@/store'

let baseUrl = store.state.baseUrl
axios.defaults.withCredentials = true

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = 'Bearer ' + store.state.accessToken
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code == 401) {
      store.commit('removeToken')
      location.reload()
    }
    return response
  },
  (error) => {
    // 对响应错误做些什么
    if (error.response) {
      // 请求已发出，但服务器返回状态码不在 2xx 范围内
      const status = error.response.status
      const data = error.response.data

      if (status === 401) {
        // 处理401 Unauthorized异常
        store.commit('removeToken')
        location.reload()
      } else if (status === 500) {
        // 处理500 Internal Server Error异常
        console.error('Internal Server Error:', data)
      } else {
        // 处理其他 HTTP 状态码异常
        console.error('HTTP Status Code:', status, data)
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      console.error('No response received:', error.request)
    } else {
      // 发送请求时出现了一些错误
      console.error('Request error:', error.message)
    }

    return Promise.reject(error)
  }
)

export default {
  invoices(params) {
    return axios.get(`${baseUrl}/get_invoices`, { params: params })
  },
  download(params) {
    return axios.get(`${baseUrl}/download`, { params: params })
  },
  preview(params) {
    return axios.get(`${baseUrl}/preview`, {
      params: params,
      responseType: 'arraybuffer',
    })
  },
  record(params) {
    return axios.post(`${baseUrl}/record`, params)
  },
  get_record(params) {
    return axios.get(`${baseUrl}/get_record`, { params: params })
  },
  get_contrast(params) {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${baseUrl}/get_contrast`,
      params: params,
    })
  },
  staff(params) {
    return axios.get(`${baseUrl}/login`, { params: params })
  },
  login(params) {
    return axios.post(`${baseUrl}/index/login`, params)
  },
  update_amount(params) {
    return axios.post(`${baseUrl}/update_amount`, params)
  },
  upload(formData) {
    return axios.post(`${baseUrl}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 需要设置正确的 Content-Type
        // 在这里可以添加其他请求头，如授权信息等
      },
    })
  },
  approve(params) {
    return axios.post(`${baseUrl}/approve`, params)
  },
  payment_approve(params) {
    return axios.post(`${baseUrl}/payment/approve`, params)
  },
  payment_list(params) {
    return axios.get(`${baseUrl}/payment/list`, { params: params })
  },
  payment_confirm(params) {
    return axios.post(`${baseUrl}/payment/confirm`, params)
  },
  wecom_signature(params) {
    return axios.get(`${baseUrl}/wecom_signature`, params)
  },
  agent_signature(params) {
    return axios.get(`${baseUrl}/agent_signature`, params)
  },
  get_approvers(params) {
    return axios.get(`${baseUrl}/get_approvers`, { params: params })
  },
}
