import { createRouter, createWebHistory } from 'vue-router'
import * as jwt from 'jose'

import HomeView from '../views/HomeView.vue'
import InvoicesView from '../views/InvoicesView.vue'

import LoginView from '../views/LoginView.vue'
import ExpenseApproval from '../views/ExpenseApproval.vue'
import PaymentApproval from '../views/PaymentApproval.vue'

import store from '@/store'

import api from '@/api'

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: InvoicesView,
        meta: { title: '发票' },
    },
    {
        path: '/approve',
        name: 'approve',
        component: ExpenseApproval,
        meta: { title: '报销' },
    },
    {
        path: '/payment',
        name: 'payment',
        component: PaymentApproval,
        meta: { title: '付款' },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// 传入JWT字符串
function isTokenExpired(token) {
    if (!token) {
        return true
    } else {
        // 解码JWT
        const decodedToken = jwt.decodeJwt(token)

        // 检查过期时间（exp字段）
        const currentTime = Math.floor(Date.now() / 1000) // 当前时间（以秒为单位）

        // 如果过期时间小于当前时间，则表示已过期
        return decodedToken.exp < currentTime
    }
}

async function getStaffInfo(code) {
    let params = {
        code: code,
    }

    const res = await api.staff(params)

    if (res.status != 200 || res.data.code != 200) {
        return false
    }

    store.commit('saveToken', res.data.data.token)
    store.state.isShowLogin = false

    return true
}

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || '审批'

    if (isTokenExpired(localStorage.getItem('accessToken'))) {
        store.state.isShowLogin = true
        localStorage.removeItem('accessToken')
    }
    if (to.name != 'login' && store.state.isShowLogin) {
        store.commit('changeTarget', to.name)
        if (to.query.code) {
            let result = await getStaffInfo(to.query.code)

            if (result) next({ name: store.state.targetName })
            else next({ name: 'login' })
        } else
            next({
                name: 'login',
            })
    } else next()
})

export default router
