<template>
  <div class="app-box light">
    <div class="app-bg" :style="'filter: blur(0px);'">
      <video
        class="app-bg-video"
        autoplay="true"
        loop="true"
        muted="true"
        :src="wallpaperSrc"
      ></video>
    </div>
    <div class="app-container app-cover d-flex-all">
      <div id="wx-login" />
    </div>
  </div>
</template>
<script>
import WwLogin from '@/utils/wwLogin-1.2.7'

export default {
  name: 'LoginView',
  data() {
    return {
      wallpaper: {
        title: '格里姆瑟尔山口Totesee山地湖中倒映出的星星',
        local: '欧洲 瑞士 伯尔尼',
        src: 'https://az3.sfo2.cdn.digitaloceanspaces.com/bingdesktop/3455b6778764415593fb5e688218fb89.mp4',
      },
      wallpaperSrc: require('../assets/3455b6778764415593fb5e688218fb89.mp4'),
      isShowTable: false,
    }
  },
  props: {},
  methods: {
    getStaffInfo(code) {
      let api = 'staff'
      let params = {
        code: code,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200 || res.data.code != 200) {
            alert(res.data)
            if (res.data.code === 401001) {
              this.$message({
                type: 'error',
                message: res.data.msg,
                showClose: true,
              })
            } else {
              this.$message({
                type: 'error',
                message: `登录失败, 请联系管理员`,
                showClose: true,
              })
            }
            return
          }
          this.$store.commit('saveToken', res.data.data.token)
          this.$store.state.isShowLogin = false
        })
        .finally(() => {
          this.$router.replace({
            // path: '/',
            name: this.$store.state.targetName,
          })
        })
    },
    onLoad() {
      this.$router.go(0)
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.$message({
        type: 'info',
        message: `登录成功`,
        showClose: true,
      })
      this.$router.replace({
        // path: '/',
        name: this.$store.state.targetName,
      })
    } else
      this.$nextTick(() => {
        this.wwLogin = new WwLogin({
          id: 'wx-login',
          appid: this.$store.state.corpId,
          agentid: this.$store.state.agentId,
          redirect_uri: encodeURIComponent(
            this.$store.state.loaclUrl + '/login/'
          ),
          state: '',
          href: '',
          lang: 'zh',
        })
      })
  },
  unmounted() {
    this.wwLogin && this.wwLogin.destroyed()
  },
  watch: {
    $route: {
      handler(to) {
        if (to.query.code) {
          this.getStaffInfo(to.query.code)
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style lang="less" scoped>
.app-box {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #333;
  .app-bg {
    position: absolute;
    backface-visibility: hidden;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    .app-bg-img,
    .app-bg-video {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
    .app-bg-img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.bgBlur {
      filter: blur(10px);
      transform: scale(1.042);
    }
  }
  .app-cover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: radial-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.2) 166%),
      linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 0% 75%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
  }
}
.wallpaper-info {
  position: absolute;
  transition-duration: 0.2s;
  color: rgba(255, 255, 255, 0.2);

  right: 10%;
  bottom: 10%;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}
// .app-header {
//   height: 0%;
// }

.app-search {
  height: 100%;
  &.active {
    height: 20%;
  }
}

// .app-table {
// }
</style>
