import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Xmwtable from 'vue3-xmw-table'

// import powerfulTable from 'el-plus-powerful-table-ts/es'
// import { LangKey } from 'el-plus-powerful-table-ts/es/locale/packages'

import '@/style/index.less'
// import '@/style/weui.css'
import '@/iconfont/iconfont.js'
import directives from '@/utils/directives'
import http from '@/api/index'

import dIcon from '@/components/public/d-icon'

import { Plugin } from 'vue-responsive-video-background-player'

const app = createApp(App)
app.config.globalProperties.isShowTable = false
app.config.globalProperties.$http = http

// import VConsole from 'vconsole'
// const vConsole = new VConsole()

// @ts-ignore
// app.use(vConsole)

app.use(store).use(router)
app.use(ElementPlus)
// app.use(powerfulTable, {
//   // 更改组件默认语言
//   locale: {
//     en: {
//       [LangKey.NoData]: '空',
//       [LangKey.PackUp]: '收起',
//       [LangKey.ReadFullText]: '展开阅读全文',
//     },
//     'zh-cn': {
//       [LangKey.NoData]: '空',
//       [LangKey.PackUp]: '收起',
//       [LangKey.ReadFullText]: '展开阅读全文',
//     },
//   },
// })
app.use(Xmwtable)
app.use(Plugin)

app.mount('#app')

Object.keys(directives).forEach((key) => app.directive(key, directives[key]))
app.component('dIcon', dIcon)
