// responseConstants.js
export const RESPONSE_CODE = {
  SUCCESS: 200,
  FAIL_PARSE: 411,
  DUPLICATE_INVOICE: 412,
  DUPLICATE_IN_APPROVAL: 431,
}

export const RESPONSE_MESSAGES = {
  [RESPONSE_CODE.SUCCESS]: (number) => `文件上传成功，发票号码：${number}`,
  [RESPONSE_CODE.FAIL_PARSE]: '发票解析失败，请联系财务处理',
  [RESPONSE_CODE.DUPLICATE_INVOICE]: '发票已重复，无法上传',
  [RESPONSE_CODE.DUPLICATE_IN_APPROVAL]: '已有相同发票在审批中',
}
