<template>
  <el-dialog
    class="app-dialog-area"
    v-model="dialogVisible"
    title="上传"
    :width="isMobile ? '90%' : '40%'"
    :top="isMobile ? '8vh' : '15vh'"
    center
    @close="handleOnClose"
  >
    <el-upload
      class="app-upload-area"
      drag
      :action="$store.state.baseUrl + '/upload'"
      accept=".pdf, .png, .jpg, .jpeg"
      :limit="1"
      :on-exceed="handleExceed"
      :show-file-list="true"
      v-model:file-list="fileList"
      :auto-upload="false"
      :on-change="handleOnChange"
      :data="formData"
      :headers="{ Authorization: 'Bearer ' + $store.state.accessToken }"
      ref="upload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :http-request="customHttpRequest"
    >
      <!-- multiple="false" -->
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        将PDF或图片文件拖到此处<br />或<em>点击上传</em>
        <!-- <br />
        <br />
        最多允许同时上传 10 个文件 -->
      </div>
      <!-- <template #tip>
        <div class="el-upload__tip">
          jpg/png files with a size less than 500kb
        </div>
      </template> -->
    </el-upload>
    <el-form
      class="app-from-area"
      :label-position="isMobile ? 'top' : 'left'"
      :model="formData"
      :rules="rules"
      status-icon
    >
      <el-form-item label="发票状态" prop="status">
        <el-select v-model="formData.status" fit-input-width>
          <el-option
            v-for="option in statusOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="报销人" prop="employee">
        <el-input v-model="formData.employee" />
      </el-form-item>
      <el-form-item label="审批ID" prop="approval_id">
        <el-input v-model="formData.approval_id" />
      </el-form-item>
      <el-form-item label="备注" prop="notes">
        <el-input v-model="formData.notes" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="app-footer-area">
        <el-button @click="handleOnClose">返回</el-button>
        <el-button type="primary" @click="buttonConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 发票重复提示弹窗 -->
  <el-dialog
    v-model="isDuplicateDialogVisible"
    title="发票已存在"
    :width="isMobile ? '90%' : '40%'"
    center
    @close="handleExistOnClose"
  >
        <!-- 使用 header 插槽自定义标题 -->
        <template v-slot:header>
        <div style="color: #F33F33;">发票已存在</div>
      </template>
    <!-- 显示已存在发票的信息 -->
    <el-descriptions
      v-if="existingInvoice"
      title="已存在发票信息"
      :border="true"
      size="default"
      :column="columnNum"
    >
      <el-descriptions-item align="center" label="审批单ID">
        {{ existingInvoice.approval_id }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="报销人">
        {{ existingInvoice.employee }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="日期">
        {{ existingInvoice.create_at }}
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <br />
    <br />
    <template #footer>
      <span class="app-footer-area">
        <el-button @click="isDuplicateDialogVisible = false">返回</el-button>
        <el-button type="primary" @click="isDuplicateDialogVisible = false"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 确认金额提示弹窗 -->
  <el-dialog
    v-model="isConfirmDialogVisible"
    title="确认发票金额"
    :width="isMobile ? '90%' : '40%'"
    center
    @close="handleConfirmClose"
  >
    <!-- 显示上传成功的发票信息 -->
    <el-descriptions
      v-if="uploadedInvoice"
      title="发票信息"
      :border="true"
      size="default"
      :column="columnNum"
    >
      <el-descriptions-item align="center" label="发票号码">
        {{ uploadedInvoice.number }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="报销人">
        {{ uploadedInvoice.employee }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="开票日期">
        {{ uploadedInvoice.date }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="金额">
        <!-- 使用 el-input 允许用户编辑金额 -->
        <el-input
          v-model="uploadedInvoice.amount"
          placeholder="请输入金额"
          clearable
          input-style="text-align: center;"
        ></el-input>
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <br />
    <br />
    <template #footer>
      <span class="app-footer-area">
        <el-button @click="isConfirmDialogVisible = false">返回</el-button>
        <el-button type="primary" @click="buttonChangeAmount"
          >确认金额并提交</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 上传成功提示弹窗 -->
  <el-dialog
    v-model="isUploadSuccessDialogVisible"
    title="上传成功"
    :width="isMobile ? '90%' : '40%'"
    center
    @close="handleUploadSuccessClose"
  >
    <!-- 显示上传成功的发票信息 -->
    <el-descriptions
      v-if="uploadedInvoice"
      title="发票信息"
      :border="true"
      size="default"
      :column="columnNum"
    >
      <el-descriptions-item align="center" label="发票号码">
        {{ uploadedInvoice.number }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="报销人">
        {{ uploadedInvoice.employee }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="开票日期">
        {{ uploadedInvoice.date }}
      </el-descriptions-item>
      <el-descriptions-item align="center" label="金额">
        {{ uploadedInvoice.amount }}
      </el-descriptions-item>
    </el-descriptions>
    <br />
    <br />
    <br />
    <template #footer>
      <span class="app-footer-area">
        <el-button @click="isUploadSuccessDialogVisible = false"
          >返回</el-button
        >
        <el-button type="primary" @click="isUploadSuccessDialogVisible = false"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { UploadFilled } from '@element-plus/icons-vue'

export default {
  name: 'TableBox',
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      dialogVisible: false,
      isDuplicateDialogVisible: false,
      isConfirmDialogVisible: false,
      isUploadSuccessDialogVisible: false,
      existingInvoice: null,
      uploadedInvoice: null,
      columnNum: 1,
      fileList: [],
      formData: {
        status: 0,
        notes: '',
        employee: '',
        approval_id: '',
      },
      statusOptions: [
        { label: '默认', value: 0 },
        { label: '待审核', value: 10 },
        { label: '已审核', value: 11 },
        { label: '已支付', value: 12 },
        { label: '重复', value: 20 },
      ],
      rules: {
        // type: [
        //   {
        //     required: true,
        //     message: '文档类型必须填写',
        //     trigger: 'blur',
        //   },
        // ],
        // keywords: [
        //   {
        //     required: true,
        //     message: '关键词必须填写',
        //     trigger: 'blur',
        //   },
        // ],
        // remarks: [
        //   {
        //     required: true,
        //     message: '备注必须填写',
        //     trigger: 'blur',
        //   },
        // ],
      },
    }
  },
  methods: {
    handleOnChange(param1, param2) {
      // this.$refs.upload.submit()
      // console.log(param1)
      // console.log(param2)
    },
    handleExceed(files, fileList) {
      this.$message.warning('超过文件上传数量限制，无法继续添加文件')
      // 禁用上传按钮或文件拖放区域
      // this.$refs.upload.abort();  // 取消上传

      // 限制单个文件的情况，自动替换文件
      // this.$refs.upload.clearFiles()
      // const file = files[0]
      // this.$refs.upload.handleStart(file)
    },
    handleUploadSuccess(response) {
      // 处理上传成功后的逻辑
      console.log(response)
      if (response && response.code == 200) {
        this.$message.success('文件上传成功')
        // 清空文件列表
        this.fileList = []
        // this.clear()
        this.formData = {
          status: 0,
          notes: '',
        }
        this.uploadedInvoice = response.data
        this.dialogVisible = false
        this.isConfirmDialogVisible = true
      } else if (response.code == 412) {
        this.$message.error('发票已重复')
        this.existingInvoice = response.data
        this.dialogVisible = false
        this.isDuplicateDialogVisible = true
      } else if (response.code == 411) {
        this.$message.error('发票解析失败，请联系财务处理')
      } else if (response.code == 431) {
        this.$message.warning('已有相同发票在审批中')
        this.dialogVisible = false
        this.isDuplicateDialogVisible = true
      }  else {
        this.$message.error('文件上传失败，请联系财务处理')
        // 可以执行失败时的其他操作
      }
    },
    handleUploadError(error) {
      // 处理上传失败后的逻辑
      console.log(error)
    },
    customHttpRequest(option) {
      console.log(option)
      const formData = new FormData()

      formData.append('is_back', 1)

      if (option.data) {
        for (const [key, value] of Object.entries(option.data)) {
          if (Array.isArray(value) && value.length) {
            formData.append(key, ...value)
          } else {
            formData.append(key, value)
          }
        }
      }

      formData.append(option.filename, option.file, option.file.name)

      // 使用之前创建的 Axios 实例进行请求
      let api = 'upload'

      this.$http[api](formData)
        .then((response) => {
          // 使用 Axios 的响应对象处理成功的情况
          option.onSuccess(response.data)
        })
        .catch((error) => {
          // 使用 Axios 的错误对象处理失败的情况
          option.onError(error)
        })
        .finally(() => {
          // this.state.loading = false
        })
    },
    buttonConfirm() {
      if (this.fileList.length === 0)
        this.$message({
          type: 'error',
          message: `未选择文件`,
          showClose: true,
        })
      // else if (this.formData.type === '' || this.formData.keywords === '')
      //   this.$message({
      //     type: 'error',
      //     message: `未填写必填项`,
      //     showClose: true,
      //   })
      else {
        this.$refs.upload.submit()
      }
    },
    handleOnAdd() {
      this.$message({
        type: 'success',
        message: `添加成功`,
        showClose: true,
      })
    },
    handleOnClose() {
      this.clear()
    },
    handleExistOnClose() {
      this.isDuplicateDialogVisible = false
      this.existingInvoice = null
      this.fileList = []
      this.formData = {
        status: 0,
        notes: '',
        approval_id: '',
        employee: '',
      }
      this.dialogVisible = true
    },
    handleUploadSuccessClose() {
      this.isUploadSuccessDialogVisible = false
      this.uploadedInvoice = null
      this.fileList = []
      this.formData = {
        status: 0,
        notes: '',
        approval_id: '',
        employee: '',
      }
      this.dialogVisible = true
    },
    handleConfirmClose() {
      this.isConfirmDialogVisible = false
    },
    buttonChangeAmount() {
      let api = 'update_amount'
      let params = {
        amount: this.uploadedInvoice.amount,
        number: this.uploadedInvoice.number,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200) return
          else if (res.data.code != 200)
            this.$message({
              type: 'error',
              message: res.data.msg,
              showClose: true,
            })
          else
            this.$message({
              type: 'success',
              message: `已确认`,
              showClose: true,
            })
          this.isConfirmDialogVisible = false
          this.isUploadSuccessDialogVisible = true
        })
        .finally(() => {
          // this.state.loading = false
        })
    },
    clear() {
      this.formData = {
        status: 0,
        notes: '',
        approval_id: '',
        employee: '',
      }
      this.fileList = []
      this.$store.state.isShowUpload = false
      this.dialogVisible = false
    },
    areArraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }
      return true
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768 // Adjust the breakpoint as needed
    },
    updateIsHasDialog() {
      // 更新 this.$store.state.isHasDialog
      this.$store.commit(
        'updateIsHasDialog',
        this.dialogVisible ||
          this.isDuplicateDialogVisible ||
          this.isConfirmDialogVisible ||
          this.isUploadSuccessDialogVisible
      )
    },
  },
  components: {
    UploadFilled,
  },
  watch: {
    '$store.state.isShowUpload': {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
      deep: true,
      immediate: true,
    },
    fileList: {
      handler(newFileList, oldFileList) {
        if (
          newFileList &&
          oldFileList &&
          !this.areArraysEqual(newFileList, oldFileList) &&
          !newFileList.length == 0
        ) {
          this.handleOnAdd()
        }
      },
      deep: true,
      immediate: true,
    },
    areAllDialogsHidden(newVal) {
      // 在三个属性同时为 false 时执行相应的操作
      if (newVal) {
        // this.$store.state.isHasDialog = false
      }
    },
    dialogVisible(newVal) {
      this.updateIsHasDialog()
    },
    isDuplicateDialogVisible(newVal) {
      this.updateIsHasDialog()
    },
    isConfirmDialogVisible(newVal) {
      this.updateIsHasDialog()
    },
    isUploadSuccessDialogVisible(newVal) {
      this.updateIsHasDialog()
    },
  },
  mounted() {
    // Listen for resize events to dynamically update isMobile
    window.addEventListener('resize', this.updateIsMobile)
  },
  beforeUnmount() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.updateIsMobile)
  },
  computed: {
    maxLabelWidth() {
      // const maxLabelLength = Math.max(...this.formItems.map(item => item.label.length));
      const maxLabelLength = 4
      const baseFontSize =
        parseInt(
          getComputedStyle(document.documentElement).getPropertyValue(
            '--el-form-label-font-size'
          ),
          10
        ) || 14
      return maxLabelLength * (baseFontSize + 4) + 'px'
    },
    areAllDialogsHidden() {
      // 返回这三个属性是否同时为 false
      return (
        !this.isDuplicateDialogVisible &&
        !this.isConfirmDialogVisible &&
        !this.isUploadSuccessDialogVisible
      )
    },
  },
}
</script>
<style lang="less">
.app-upload-box {
  width: 20%;
}
.app-dialog-area {
  .el-dialog {
    /* Default margin for larger screens */
    margin: var(--el-dialog-margin-top, 15vh) auto 50px;
  }
}

/* Media query for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 900px) {
  .app-dialog-area {
    margin: 5vh auto; /* Adjust the margin for smaller screens */

    .el-dialog__body {
      padding: 15px calc(var(--el-dialog-padding-primary) + 5px) 20px;
    }
    .el-dialog__footer {
      padding-bottom: 10px;
    }
  }
  .app-upload-area {
    :deep(.el-upload .el-upload-dragger) {
      width: 100%;
      height: 30%;
    }
    .el-upload-dragger {
      padding: 2vh 3vh;
    }
  }

  :deep(.el-dialog) {
    margin: 10px 10px !important;
  }
}

.app-footer-area {
  .el-button {
    margin: -50px 20px 0px 20px;
  }
}
</style>

<style lang="less" scoped>
// scoped 才生效

.app-upload-area {
  width: 80%;
  margin: auto;

  :deep(.el-icon-upload:before) {
    content: '\e7c3'; //设置图标
  }
  .el-upload-dragger .el-icon--upload {
    font-size: 45px; //图标的尺寸大小
    margin: 4px 0 2px; //图标与四周元素的间距
  }
  //下面这几行代码就是设置上传框的长宽，可以按百分比来设置也可以用px去设置
  :deep(.el-upload .el-upload-dragger) {
    width: 100%;
    height: 30%;
  }
}
.app-from-area {
  width: 80%;
  margin: 30px auto;
  //   max-width: 460px;

  .el-select {
    width: 100%;
  }
  :deep(.el-form-item label:after) {
    content: '';
    width: 100%;
  }
  :deep(.el-form-item__label) {
    /* display: inline-block必须要有，不然布局出问题，之前我看别人都加在伪类中，我试了没效果 */
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
    font-weight: bold;
    width: (4 + 14) * 4px;
  }
  /* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
  :deep(.el-form-item.is-required .el-form-item__label:before) {
    content: none !important;
  }
}
</style>
