<template>
  <el-config-provider :locale="locale">
    <div class="d-flex-hor full">
      <vue3-xmw-table
        stripe
        border
        height="100%"
        width="100%"
        :header-cell-style="getHeaderCell"
        :cell-style="getCellHeight"
        :tableData="state.tableData"
        :loading="state.loading"
        :columns="state.tableColumns"
        :tableConfig="tableConfig"
        :showPagination="true"
        :paginationConfig="pageConfig"
        @page-size-change="pageSizeChange"
        @current-page-change="currentPageChange"
        @row-click="rowClick"
        ref="table"
      >
        <template v-slot:handler="{ scope }">
          <el-button-group class="app-button-area">
            <el-button
              class="link-button"
              size="small"
              @click="handlerRecord(scope)"
              >记录</el-button
            >
            <el-button
              class="link-button"
              size="small"
              @click="handlerDownload(scope)"
              >下载</el-button
            >
          </el-button-group>
        </template>
      </vue3-xmw-table>
    </div>
  </el-config-provider>
  <el-dialog
    v-model="$store.state.isShowDownload"
    title="下载"
    width="30%"
    center
    @close="handleOnClose"
  >
    <el-form
      class="app-from-area"
      label-position="left"
      label-width="100px"
      :model="formData"
      :rules="rulesDownload"
      status-icon
      @submit.prevent
    >
      <el-form-item label="下载用途" prop="purpose">
        <el-input v-model="formData.purpose" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="app-footer-area">
        <el-button @click="$store.state.isShowDownload = false">返回</el-button>
        <el-button type="primary" @click="buttonDownloadConfirm"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="$store.state.isShowRecord"
    title="下载记录"
    width="70%"
    center
    @close="handleOnClose"
  >
    <el-scrollbar max-height="500px">
      <el-descriptions
        :title="recordTitle"
        direction="horizontal"
        :column="3"
        size="default"
        border
      >
        <template v-for="item in recordData" :key="item">
          <el-descriptions-item label="使用人" min-width="5" align="center">{{
            item.user
          }}</el-descriptions-item>
          <el-descriptions-item label="下载时间" min-width="5" align="center">{{
            item.time
          }}</el-descriptions-item>
          <el-descriptions-item label="用途" min-width="100" align="center">{{
            item.purpose
          }}</el-descriptions-item>
        </template>
      </el-descriptions>
    </el-scrollbar>
    <template #footer>
      <span class="app-footer-area-record">
        <el-button @click="$store.state.isShowRecord = false">返回</el-button>
        <el-button type="primary" @click="$store.state.isShowRecord = false"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="isShowPreview"
      title="预览"
      width="80%"
      center
      @close="handleOnClose"
    >
    <template v-if="isShowPreview">
        <div v-if="dataType === 'application/pdf'" style="height: calc(70vh - 20px)">
          <!-- Display PDF Viewer -->
          <embed :src="pdfBlobUrl" type="application/pdf" width="100%" height="100%" />
        </div>
        <div v-else-if="['image/png', 'image/jpeg'].includes(dataType)">
          <!-- Display Image Viewer -->
          <img :src="imageData" alt="Image" style="max-width: 100%; max-height: calc(70vh - 20px); display: block; margin: auto;" />
        </div>
        <div v-else>
          <p>Unsupported data type or no data available.</p>
        </div>
      </template>
    </el-dialog>
</template>

<script>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

export default {
  name: 'TableBox',
  data() {
    return {
      state: {
        tableData: [],
        loading: false,
        tableColumns: [
          {
            label: '发票类型',
            prop: 'type',
            minWidth: 120,
            align: 'center',
          },
          {
            label: '发票代码',
            prop: 'code',
            minWidth: 90,
            align: 'center',
          },
          {
            label: '发票号码',
            prop: 'number',
            minWidth: 120,
            align: 'center',
          },
          {
            label: '开票金额',
            prop: 'amount',
            minWidth: 80,
            align: 'center',
          },
          {
            label: '开票日期',
            prop: 'date',
            minWidth: 80,
            align: 'center',
          },
          {
            label: '发票状态',
            prop: 'status',
            minWidth: 50,
            align: 'center',
          },
          {
            label: '备注',
            prop: 'notes',
            minWidth: 100,
            align: 'center',
          },
          {
            label: '上传时间',
            prop: 'create_at',
            minWidth: 100,
            align: 'center',
          },
          {
            label: '报销人',
            prop: 'employee',
            minWidth: 50,
            align: 'center',
          },
          {
            label: '审批ID',
            prop: 'approval_id',
            minWidth: 80,
            align: 'center',
          },
        ],
      },
      tableConfig: {
        showHandler: true,
        showIndexColumn: true,
        isCheckMemory: true,
        handlerConfig: {
          align: 'center',
          fixed: 'right',
          minWidth: 80,
          // width: 120,
        },
      },
      locale: zhCn,
      pageConfig: {
        current: 1,
        pageSize: 15,
        total: 0,
        small: false,
        background: true,
        pageSizes: [15, 30, 50, 100],
        layout: '->, total, sizes, prev, pager, next, jumper',
      },
      formData: { purpose: '' },
      rulesDownload: {
        purpose: [
          {
            required: true,
            message: '文档用途必须填写',
            trigger: 'blur',
          },
        ],
      },
      recordData: [],
      recordTitle: '',
      isShowPreview: false,
      wordText: '',
      wordView: '',
      iframeSrc: '',
      dataType: '',       // Data type (pdf, png, jpg)
      pdfBlob: '',         // PDF Blob data
      imageBase64: '',     // Base64-encoded image data
    }
  },
  methods: {
    getCellHeight() {
      // let height = this.$refs.table.$el
      let haederHeight = this.$refs.table.$el.querySelector(
        '.el-table__body-wrapper'
      ).offsetHeight

      let cellHeight = haederHeight / 15
      return {
        height: cellHeight + 'px',
      }
    },
    getHeaderCell() {
      // let height = this.$refs.table.$el
      let haederCellHeight = 15

      return     {
          background: 'var(--el-fill-color-light)',
          height: haederCellHeight + 'px',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap !important',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
    },
    loadData() {
      this.state.loading = true

      let api = 'invoices'
      let params = {
        page: this.pageConfig.current,
        pagesize: this.pageConfig.pageSize,
        keyword: this.$store.state.searchKeyword,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200) return
          // this.$message({
          //   type: 'success',
          //   message: `成功`,
          //   showClose: true,
          // })
          this.state.tableData = res.data.data
          this.pageConfig.total = res.data.total
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    pageSizeChange(pageSize) {
      console.log(pageSize)
      this.pageConfig.current = 1
      this.pageConfig.pageSize = pageSize
      this.loadData()
    },
    currentPageChange(pageIndex) {
      console.log(pageIndex)
      this.pageConfig.current = pageIndex
      this.loadData()
    },
    handlerDownload(scope) {
      this.$store.state.isShowDownload = true
      this.rowId = scope.row.id
      this.rowTitle = scope.row.title
    },
    handlerRecord(scope) {
      let api = 'get_record'
      let params = {
        doc_id: scope.row.id,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200) return
          else if (res.data.code !== 200) console.log()
          else {
            this.recordData = res.data.data

            if (this.recordData.length == 0)
              this.$message({
                type: 'warning',
                message: `无下载记录`,
                showClose: true,
              })
            this.recordTitle = scope.row.title
            this.$store.state.isShowRecord = true
          }
        })
        .finally(() => {})
    },
    handleOnClose() {
      this.$store.state.isShowDownload = false
      this.$store.state.isShowRecord = false
      this.isShowPreview = false
    },
    buttonDownloadConfirm() {
      if (this.formData.purpose === '') {
        this.$message({
          type: 'error',
          message: `未填写文档用途`,
          showClose: true,
        })
        return
      }

      this.state.loading = true
      this.$store.state.isShowDownload = false

      let api = 'record'
      let params = {
        purpose: this.formData.purpose,
        invoice_id: this.rowId,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200) return
          else if (res.data.code !== 200)
            this.$message({
              type: 'warning',
              message: `当日下载已超限了哟`,
              showClose: true,
            })
          else {
            this.download()
            this.formData.purpose = ''
          }
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    buttonRecordConfirm() {},
    download() {
      const url = `${this.$store.state.baseUrl}download?id=${this.rowId}&access_token=${this.$store.state.accessToken}`;
      const link = document.createElement('a');
      link.href = url;
      link.download = 'filename.ext'; // 可以自定义文件名

      this.$message({
        type: 'info',
        message: '开始下载',
        showClose: true,
      });

      this.state.loading = true; // 设置加载状态

      link.click();

      // 监听下载完成事件（建议在点击链接后执行，以确保下载完成后取消加载状态）
      link.addEventListener('load', () => {
        this.state.loading = false; // 取消加载状态
      });
    },
    rowClick(row, column, event) {
      if (column.label == '操作') return

      this.state.loading = true

      let api = 'preview'
      let params = {
        id: row.id,
      }

      this.$http[api](params)
        .then((res) => {
          if (res.status != 200) return

          let dataType = ''; // Initialize with an empty string

          // Determine the data type based on the Content-Type header
          const contentType = res.headers['content-type']

          if (contentType === 'application/pdf') {
            this.dataType = contentType;
            this.pdfBlobUrl = URL.createObjectURL(new Blob([res.data], { type: contentType }));
          } else if (['image/png', 'image/jpeg'].includes(contentType)) {
            this.dataType = contentType;
            this.imageData = URL.createObjectURL(new Blob([res.data], { type: contentType }));
          } else {
            this.dataType = ''; // Unsupported data type
          }

          this.isShowPreview = true;
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    waitIframe() {
      var iframe = document.querySelector('iframe')

      if (iframe) {
        setTimeout(() => {
          console.log(iframe)
          iframe.style['-webkit-touch-callout'] = 'none'
          iframe.style['-webkit-user-select'] = 'none'
          iframe.style['-khtml-user-select'] = 'none'
          iframe.style['-moz-user-select'] = 'none'
          iframe.style['-ms-user-select'] = 'none'
          iframe.style['user-select'] = 'none'
        }, 1000)
      } else {
        setTimeout(() => {
          this.waitIframe()
        }, 500)
      }
    },
    createIframe(src) {
      const iframe = document.createElement('iframe')
      iframe.style.width = '100%'
      iframe.style.height = '100%'
      iframe.style.margin = '0'
      iframe.style.padding = '0'
      iframe.style.overflow = 'hidden'
      iframe.style.border = 'none'
      iframe.src = src
      iframe.id = 'reportIframe'
      iframe.allow = 'fullscreen'
      document.querySelector('#report-iframe').appendChild(iframe)
      const that = this
      iframe.contentDocument.body.onload = function () {
        const doc = document
          .querySelector('#report-iframe iframe')
          .contentWindow.document.getElementById('print')
        doc.addEventListener('click', function (e) {
          that.markPrint()
        })
      }
    },
    handleScreenWidthChange() {
      // 根据屏幕宽度动态调整分页配置
      if (window.innerWidth < 768) {
        this.pageConfig.layout = '->, total, prev, pager, next';
        this.pageConfig.pagerCount = 4
        // this.pageConfig.small = true;
      } else {
        this.pageConfig.layout = '->, total, sizes, prev, pager, next, jumper';
        this.pageConfig.pagerCount = 7
        this.pageConfig.small = false;
      }
    },
    // 表头部重新渲染
    renderHeader ({ column, $index }) {
      console.log(column)
      console.log($index)
      // 新建一个 span
      const span = document.createElement('span')
      // 设置表头名称
      span.innerText = column.label
      // 临时插入 document
      document.body.appendChild(span)
      // 重点：获取 span 最小宽度，设置当前列，注意这里加了 20，字段较多时还是有挤压，且渲染后的 div 内左右 padding 都是 10，所以 +20 。（可能还有边距/边框等值，需要根据实际情况加上）
      column.minWidth = span.getBoundingClientRect().width + 20
      // 移除 document 中临时的 span
      document.body.removeChild(span)
      return column.label
    }
  },
  mounted(){
    this.handleScreenWidthChange(); // 初始加载时处理一次
    window.addEventListener('resize', this.handleScreenWidthChange)
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.handleScreenWidthChange)
  },
  props: {},
  watch: {
    '$store.state.searchButton': {
      handler(newValue, oldValue) {
        if (oldValue !== undefined) this.loadData()
      },
      deep: true,
      immediate: true,
    },
    '$store.state.isShowUpload': {
      handler(newValue, oldValue) {
        if (oldValue === true && newValue === false) this.loadData()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
.common-table {
  width: 95%;
  height: 95%;
  // border-radius: 10px;
  // box-shadow: 1px 1px 5px grey;
  :deep(.el-pagination) {
    margin-top: 5px;
  }
  :deep(.el-table--fit) {
    border-radius: 10px 10px 10px 10px;
    border-top: 1px solid #c5c5c5;
    border-left: 1px solid #c5c5c5;
    // border-bottom: 3px solid #c5c5c5;
  }
}
.full {
  height: 100%;
  width: 100%;
}
:deep(.el-table__header) {
  display: inline-block; // !important
}
.common-table-header {
  // height: 30px;
  // display: block;
}
.app-from-area {
  width: 90%;
  margin: 30px auto;
  //   max-width: 460px;
}
.app-footer-area {
  .el-button {
    margin: -50px 20px 0px 20px;
  }
}
.app-footer-area-record {
  .el-button {
    margin: -10px 20px 0px 20px;
  }
}
.app-button-area {
  display: inline-block;
  vertical-align: middle;
}
.app-word {
  width: 100%;
  height: calc(100vh - var(--el-dialog-margin-top, 15vh) * 2 - 50px);
  justify-content: center;
  align-items: center;
  background-color: #fff;
  word-break: break-all;
  overflow-y: scroll;
  border: 5px solid #c2c1c1;
  border-radius: 10px;

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #f5f5f5;
  }
  :deep(img) {
    visibility: hidden;
  }
  // :deep(span) {
  //   // position: relative;
  //   // visibility: hidden;
  // }
}
.preview-dialog-height {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  :deep(.el-dialog) {
    margin: 0 auto !important;
    height: 80%;
    overflow: hidden;
    background-color: black;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
      // 下边设置字体，我的需求是黑底白字
      color: #ffffff;
      line-height: 30px;
      padding: 0 15px;
    }
  }
}
.el-dialog-div {
  height: 70vh;
  overflow: auto;
}

.app-iframe {
  width: 100%;
  height: calc(100vh - var(--el-dialog-margin-top, 15vh) * 2 - 50px);
  .body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.link-button {
  color: var(--el-color-primary);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
</style>
