<template>
  <div class="page-container">
    <!-- 页头（留空） -->
    <div class="header"></div>
    <ConfigProvider componentSize="large" :theme="configTheme">
      <!-- 正文 -->
      <div class="content">
        <!-- 报销表单 -->
        <a-form
          :model="formState"
          class="expense-form"
          :label-col="labelCol"
          labelAlign="right"
          ref="formRef"
        >
          <!-- 基本信息卡片 -->
          <a-card
            class="info-card"
            :head-style="{
              fontSize: '12px',
              textAlign: 'left',
              minHeight: '40px',
            }"
            :body-style="{ padding: '10px' }"
          >
            <template #title>
              <span class="card-title">基本信息</span>
            </template>
            <a-form-item
              label="报销类型"
              :name="['baseInfo', 'infoType']"
              :rules="[{ required: true, message: '请选择报销类型' }]"
            >
              <a-select
                v-model:value="formState.baseInfo.infoType"
                placeholder="请选择"
                style="width: 100%; text-align: left"
                @change="handleChange"
              >
                <a-select-option value="差旅费">差旅费</a-select-option>
                <a-select-option value="交通费">交通费</a-select-option>
                <a-select-option value="招待费">招待费</a-select-option>
                <a-select-option value="加班及打车费用"
                  >加班及打车费用</a-select-option
                >
                <a-select-option value="其他">其他</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="报销事由">
              <a-input
                v-model:value="formState.baseInfo.infoReason"
                placeholder="请填写"
                @change="handleChange"
              />
            </a-form-item>

            <a-form-item
              @click="openContactSelectorDepartment"
              :name="['baseInfo', 'infoDepartmentName']"
              :rules="[{ required: true, message: '请选择支出所属战区部门' }]"
            >
              <template #label>
                <div style="text-align: center">支出所属<br />战区部门</div>
              </template>
              <a-input
                v-model:value="formState.baseInfo.infoDepartmentName"
                placeholder="请根据实际情况如实选择支出所属战区部门"
                @change="handleChange"
              >
                <!-- <template #prefix>
                  <user-outlined />
                </template> -->
                <template #suffix>
                  <PlusOutlined />
                </template>
              </a-input>
            </a-form-item>

            <!-- 报销人信息 -->
            <a-form-item
              label="报销人"
              :name="['baseInfo', 'claimantName']"
              :rules="[{ required: true, message: '请输入报销人' }]"
            >
              <a-input
                v-model:value="formState.baseInfo.claimantName"
                placeholder="请填写"
                @change="handleChange"
              />
            </a-form-item>

            <!-- 银行卡号信息 -->
            <a-form-item
              label="银行卡号"
              :name="['baseInfo', 'bankAccountNumber']"
              :rules="[{ required: true, message: '请输入银行卡号' }]"
            >
              <a-input
                v-model:value="formState.baseInfo.bankAccountNumber"
                placeholder="请填写"
                @change="handleChange"
              />
            </a-form-item>

            <!-- 开户行信息 -->
            <a-form-item
              label="开户行"
              :name="['baseInfo', 'bankName']"
              :rules="[{ required: true, message: '请输入开户行' }]"
            >
              <a-input
                v-model:value="formState.baseInfo.bankName"
                placeholder="请填写"
                @change="handleChange"
              />
            </a-form-item>

            <!-- 是否冲账信息 -->
            <a-form-item
              label="是否冲账"
              :name="['baseInfo', 'isOffsetting']"
              :rules="[{ required: true, message: '请选择是否冲账' }]"
            >
              <a-select
                v-model:value="formState.baseInfo.isOffsetting"
                style="width: 100%; text-align: left"
                placeholder="请选择是否冲账"
                @change="handleChange"
              >
                <a-select-option value="是">是</a-select-option>
                <a-select-option value="否">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-card>

          <!-- 报销明细卡片 -->
          <div v-for="(detail, index) in formState.detailInfo" :key="index">
            <a-card
              class="detail-card"
              :head-style="{
                fontSize: '12px',
                textAlign: 'left',
                minHeight: '40px',
              }"
              :body-style="{ padding: '10px' }"
            >
              <template #title>
                <span class="expense-detail-title"
                  >报销明细 {{ index + 1 }}</span
                ></template
              >
              <template #extra>
                <a-button
                  type="link"
                  @click="removeExpenseDetail(index)"
                  v-show="formState.detailInfo.length > 1"
                  style="font-size: 12px"
                  >删除</a-button
                ></template
              >
              <a-form-item
                label="费用类型"
                :name="['detailInfo', index, 'expenseType']"
                :rules="[{ required: true, message: '请选择费用类型' }]"
              >
                <a-select
                  v-model:value="detail.expenseType"
                  placeholder="请选择"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                >
                  <a-select-option value="飞机票">飞机票</a-select-option>
                  <a-select-option value="火车票">火车票</a-select-option>
                  <a-select-option value="交通费">交通费</a-select-option>
                  <a-select-option value="住宿费">住宿费</a-select-option>
                  <a-select-option value="餐饮费">餐饮费</a-select-option>
                  <a-select-option value="礼品费">礼品费</a-select-option>
                  <a-select-option value="活动费">活动费</a-select-option>
                  <a-select-option value="通讯费">通讯费</a-select-option>
                  <a-select-option value="补助">补助</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                label="发生时间"
                :name="['detailInfo', index, 'expenseDate']"
                :rules="[{ required: true, message: '请选择发生时间' }]"
              >
                <a-date-picker
                  v-model:value="detail.expenseDate"
                  placeholder="请选择"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                label="费用金额"
                :name="['detailInfo', index, 'expenseAmount']"
                :rules="[
                  { type: 'number', required: true, message: '请选择费用金额' },
                ]"
              >
                <a-tooltip
                  placement="top"
                  title="超过三千元必须再添加杜总审批，投标保证金、和复印社返款10%以内，无需董事长审批"
                >
                  <a-input-number
                    v-model:value="detail.expenseAmount"
                    :step="0.01"
                    :min="0"
                    :max="99999999"
                    :controls="false"
                    addon-after="元"
                    placeholder="超过三千元必须再添加杜总审批，投标保证金、和复印社返款10%以内，无需董事长审批"
                    style="width: 100%; text-align: left"
                    @change="handleChange"
                  />
                </a-tooltip>
              </a-form-item>

              <a-form-item label="费用说明">
                <a-textarea
                  v-model:value="detail.expenseDescription"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>

              <!-- 上传发票表单项 -->
              <a-form-item label="上传发票">
                <a-upload
                  accept="image/jpeg,image/png,application/pdf"
                  :action="uploadInvoiceUrl"
                  :before-upload="beforeUpload"
                  :file-list="detail.invoiceFileList"
                  :headers="{
                    Authorization: 'Bearer ' + $store.state.accessToken,
                  }"
                  :show-upload-list="{
                    showRemoveIcon: true,
                  }"
                  @change="handleInvoiceChange(index, $event)"
                  multiple
                  :timeout="60000"
                >
                  <a-button>
                    <CloudUploadOutlined />
                    上传发票
                  </a-button>
                  <template #itemRender="{ file, actions }">
                    <div
                      style="
                        max-width: 100%;
                        display: inline-block;
                        line-height: 1;
                      "
                    >
                      <a-spin
                        v-if="file.status === 'uploading'"
                        :indicator="LoadingOutlined"
                      />

                      <ExclamationCircleOutlined
                        v-if="
                          file.status === 'error' ||
                          (file.response && file.response.code !== 200)
                        "
                        style="
                          color: red;
                          margin-right: 8px;
                          vertical-align: middle;
                        "
                      />
                      <span
                        :style="{
                          color: file.status === 'error' ? 'red' : '',
                          display: 'inline-block',
                          maxWidth: '80%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }"
                        >{{ file.name }}</span
                      >
                      <a
                        href="javascript:;"
                        @click="actions.remove"
                        style="margin-left: 8px; vertical-align: middle"
                        >删除</a
                      >
                    </div>
                  </template>
                </a-upload>
              </a-form-item>
              <a-form-item label="添加附件">
                <a-upload
                  :action="uploadAttachmentUrl"
                  :before-upload="beforeUpload"
                  :file-list="detail.attachmentFileList"
                  :headers="{
                    Authorization: 'Bearer ' + $store.state.accessToken,
                  }"
                  @change="handleAttachmentChange(index, $event)"
                >
                  <a-button :disabled="isExceedingFileLimit()">
                    <CloudUploadOutlined />
                    添加附件
                  </a-button>
                  <template #itemRender="{ file, actions }">
                    <div
                      style="
                        max-width: 100%;
                        display: inline-block;
                        line-height: 1;
                      "
                    >
                      <a-spin
                        v-if="file.status === 'uploading'"
                        :indicator="LoadingOutlined"
                      />
                      <ExclamationCircleOutlined
                        v-if="
                          file.status === 'error' ||
                          (file.response && file.response.code !== 200)
                        "
                        style="
                          color: red;
                          margin-right: 8px;
                          vertical-align: middle;
                        "
                      />

                      <span
                        :style="{
                          color: file.status === 'error' ? 'red' : '',
                          display: 'inline-block',
                          maxWidth: '80%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }"
                        >{{ file.name }}</span
                      >
                      <a
                        href="javascript:;"
                        @click="actions.remove"
                        style="margin-left: 8px; vertical-align: middle"
                        >删除</a
                      >
                    </div>
                  </template>
                </a-upload>
              </a-form-item>
            </a-card>
          </div>
          <!-- 增加报销明细卡片 -->
          <a-card
            class="info-card"
            :head-style="{
              fontSize: '12px',
              textAlign: 'left',
              minHeight: '40px',
            }"
            :body-style="{ padding: '10px' }"
          >
            <!-- 增加报销明细按钮 -->
            <a-button
              type="link"
              @click="addExpenseDetail"
              style="font-size: 14px"
              v-if="formState.detailInfo.length < maxExpenseDetails"
            >
              <plus-outlined key="plus" />
              <span>增加报销明细</span>
            </a-button>
          </a-card>

          <!-- 审批流程卡片 -->
          <a-card
            class="detail-card"
            title="审批流程"
            :head-style="{
              fontSize: '12px',
              textAlign: 'left',
              minHeight: '40px',
            }"
            :body-style="{ padding: '10px' }"
          >
            <a-timeline
              mode="left"
              style="
                /* display: flex; */
                /* flex-direction: row; */
                /* align-items: flex-start; */
                /* text-align: left; */
              "
            >
              <!-- 上级审批时间线 -->
              <a-timeline-item>
                <template #dot>
                  <!-- 自定义节点图标 -->
                  <div>
                    <AuditOutlined />
                  </div>
                </template>
                <!-- 上级审批内容 -->
                <div style="text-align: left">
                  <!-- 上级审批标题 -->
                  <div
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      /* margin-bottom: 10px; */
                      /* margin-top: 10px; */
                      margin: 10px 0px;
                    "
                  >
                    上级审批
                  </div>
                  <!-- 上级审批列表 -->
                  <a-space wrap :style="{ marginBottom: '5px' }">
                    <template
                      v-for="(user, index) in approverList"
                      :key="index"
                    >
                      <a-card
                        hoverable
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          /* margin-bottom: 10px; */
                          border-radius: 10px; /* 设置整体的圆角半径 */
                          overflow: hidden; /* 保证内容在圆角处不溢出 */
                        "
                        :bodyStyle="{ padding: '10px 5px' }"
                      >
                        <a-card-meta
                          :title="user.name"
                          style="align-items: center"
                        >
                          <template #avatar>
                            <a-avatar
                              shape="square"
                              :src="user.avatar"
                              v-if="user.avatar"
                            />
                            <a-avatar
                              shape="square"
                              style="background-color: #267ef0"
                              v-else
                              >{{ user.name.slice(-2) }}</a-avatar
                            >
                          </template>
                        </a-card-meta>
                        <template #actions>
                          <close-outlined
                            :style="{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }"
                            @click="() => removeApprover(index)"
                          />
                        </template>
                      </a-card>
                      <arrow-right-outlined />
                    </template>
                    <plus-outlined
                      class="custom-plus-btn"
                      @click="openContactSelectorSingle('superior')"
                    />
                  </a-space>
                </div>
              </a-timeline-item>

              <!-- 财务审批时间线 -->
              <a-timeline-item>
                <template #dot>
                  <!-- 自定义节点图标 -->
                  <div>
                    <DollarCircleOutlined />
                  </div>
                </template>
                <!-- 财务审批内容 -->
                <div style="text-align: left">
                  <!-- 财务审批标题 -->
                  <div
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      margin-bottom: 10px;
                    "
                  >
                    财务审批
                  </div>
                  <!-- 财务审批列表 -->
                  <a-space wrap :style="{ marginBottom: '5px' }">
                    <template
                      v-for="(financeUser, index) in financeApproverList"
                      :key="index"
                    >
                      <a-card
                        hoverable
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        :bodyStyle="{ padding: '10px 5px' }"
                      >
                        <a-card-meta
                          :title="financeUser.name"
                          style="align-items: center"
                        >
                          <template #avatar>
                            <a-avatar
                              shape="square"
                              :src="financeUser.avatar"
                              v-if="financeUser.avatar"
                            />
                            <a-avatar
                              shape="square"
                              style="background-color: #267ef0"
                              v-else
                              >{{ financeUser.name.slice(-2) }}</a-avatar
                            >
                          </template>
                        </a-card-meta>
                        <template #actions>
                          <close-outlined
                            :style="{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }"
                            @click="() => removeFinanceApprover(index)"
                          />
                        </template>
                      </a-card>
                      <arrow-right-outlined />
                    </template>
                    <plus-outlined
                      class="custom-plus-btn"
                      @click="openContactSelectorSingle('finance')"
                    />
                  </a-space>
                </div>
              </a-timeline-item>

              <!-- 抄送人时间线 -->
              <a-timeline-item>
                <template #dot>
                  <!-- 自定义节点图标 -->
                  <div>
                    <SendOutlined />
                  </div>
                </template>
                <!-- 抄送人内容 -->
                <div style="text-align: left">
                  <!-- 抄送人标题 -->
                  <div
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      margin-bottom: 10px;
                    "
                  >
                    抄送人
                  </div>
                  <!-- 抄送人列表 -->
                  <a-space wrap :style="{ marginBottom: '5px' }">
                    <template
                      v-for="(ccUser, index) in ccApproverList"
                      :key="index"
                    >
                      <a-card
                        hoverable
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        :bodyStyle="{ padding: '10px 5px' }"
                      >
                        <a-card-meta
                          :title="ccUser.name"
                          style="align-items: center"
                        >
                          <template #avatar>
                            <a-avatar
                              shape="square"
                              :src="ccUser.avatar"
                              v-if="ccUser.avatar"
                            />
                            <a-avatar
                              shape="square"
                              style="background-color: #267ef0"
                              v-else
                              >{{ ccUser.name.slice(-2) }}</a-avatar
                            >
                          </template>
                        </a-card-meta>
                        <template #actions>
                          <close-outlined
                            :style="{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }"
                            @click="() => removeCcApprover(index)"
                          />
                        </template>
                      </a-card>
                      <arrow-right-outlined />
                    </template>
                    <plus-outlined
                      class="custom-plus-btn"
                      @click="openContactSelectorSingle('cc')"
                    />
                  </a-space>
                </div>
              </a-timeline-item>
            </a-timeline>
          </a-card>
        </a-form>
      </div>

      <!-- 页脚 -->
      <div class="footer">
        <div class="button-container">
          <a-button
            type="primary"
            :loading="isSubmitting"
            @click="submitApprove"
            >提交</a-button
          >
          <a-button @click="cancel">取消</a-button>
        </div>
      </div>
    </ConfigProvider>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Select,
  SelectOption,
  Input,
  DatePicker,
  Textarea,
  Button,
  ConfigProvider,
  Card,
  Upload,
  Modal,
  Space,
  Avatar,
  CardMeta,
  Timeline,
  TimelineItem,
  Spin,
  InputNumber,
  Tooltip,
  message,
} from 'ant-design-vue'
import {
  CloudUploadOutlined,
  CloseOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined,
  SendOutlined,
  DollarCircleOutlined,
  AuditOutlined,
  UploadOutlined,
  PictureOutlined,
  FilePdfOutlined,
  FileOutlined,
  UserOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'

import { h } from 'vue'

import * as ww from '@wecom/jssdk'
import axios from 'axios'

import { RESPONSE_CODE, RESPONSE_MESSAGES } from '@/responseConstants.js'

export default {
  components: {
    AForm: Form,
    AFormItem: FormItem,
    ASelect: Select,
    ASelectOption: SelectOption,
    AInput: Input,
    ADatePicker: DatePicker,
    ATextarea: Textarea,
    AButton: Button,
    ConfigProvider: ConfigProvider,
    ACard: Card,
    AUpload: Upload,
    CloudUploadOutlined: CloudUploadOutlined,
    CloseOutlined: CloseOutlined,
    PlusOutlined: PlusOutlined,
    ASpace: Space,
    AAvatar: Avatar,
    ACardMeta: CardMeta,
    ArrowRightOutlined: ArrowRightOutlined,
    ATimeline: Timeline,
    ATimelineItem: TimelineItem,
    SendOutlined: SendOutlined,
    DollarCircleOutlined: DollarCircleOutlined,
    AuditOutlined: AuditOutlined,
    ASpin: Spin,
    ExclamationCircleOutlined: ExclamationCircleOutlined,
    AInputNumber: InputNumber,
    ATooltip: Tooltip,
    // UploadOutlined: UploadOutlined,
    // AIcon: Icon,
    // PictureOutlined: PictureOutlined,
    // FilePdfOutlined: FilePdfOutlined,
    // FileOutlined: FileOutlined,
  },
  data() {
    return {
      formState: {
        baseInfo: {
          infoType: undefined,
          infoReason: undefined,
          infoDepartment: undefined,
          claimantName: undefined,
          bankAccountNumber: undefined,
          bankName: undefined,
          isOffsetting: '否',
          infoDepartmentName: undefined,
        },
        detailInfo: [
          // 初始的报销明细
          {
            expenseType: undefined,
            expenseDate: undefined,
            expenseAmount: undefined,
            expenseDescription: undefined,
            invoiceFileList: [],
            attachmentFileList: [],
          },
        ],
      },
      maxExpenseDetails: 5, // 最大费用明细项数
      maxFileCountLimit: 5, // 最大附件数，总计自建应用可提交6个附件，发票汇总word占用一个
      configTheme: {
        components: {
          Form: {
            screenXSMax: 0,
          },
        },
      },
      labelCol: { style: { width: '80px' } },
      uploadInvoiceUrl: this.$store.state.baseUrl + '/upload', // 上传发票的地址
      uploadAttachmentUrl: this.$store.state.baseUrl + '/upload_media', // 上传附件的地址
      approverList: [
        // {
        //   avatar:
        //     'http://wework.qpic.cn/bizmail/cqEunz4tvPcJEe8J4fME8icXyolgI271x9noELHY6bASf5T0BoLTyPg/0',
        //   id: 'CheXuanDong',
        //   name: '车炫东',
        // },
      ], // 从企业微信获取的审批人列表
      financeApproverList: [],
      ccApproverList: [],
      LoadingOutlined: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      isSubmitting: false,
      invoiceNumberList: [],
    }
  },
  methods: {
    addExpenseDetail() {
      // 增加报销明细
      if (this.formState.detailInfo.length < this.maxExpenseDetails) {
        this.formState.detailInfo.push(
          // 添加的报销明细
          {
            expenseType: undefined,
            expenseDate: undefined,
            expenseAmount: undefined,
            expenseDescription: undefined,
            invoiceFileList: [],
            attachmentFileList: [],
          }
        )
      } else {
        this.showModal('warning', '最多添加五条明细哦')
      }
    },
    removeExpenseDetail(index) {
      // 删除费用明细项
      if (this.formState.detailInfo.length > 1) {
        console.log(this.formState.detailInfo)
        console.log(index)
        this.formState.detailInfo.splice(index, 1)
        console.log(this.formState.detailInfo)
      }
    },
    clearData() {
      this.formState = {
        baseInfo: {
          infoType: undefined,
          infoReason: undefined,
          infoDepartment: undefined,
          claimantName: undefined,
          bankAccountNumber: undefined,
          bankName: undefined,
          isOffsetting: '否',
        },
        detailInfo: [
          // 初始的报销明细
          {
            expenseType: undefined,
            expenseDate: undefined,
            expenseAmount: undefined,
            expenseDescription: undefined,
            invoiceFileList: [],
            attachmentFileList: [],
          },
        ],
      }
      // this.approverList = []
      // this.financeApproverList = []
      // this.ccApproverList = []
    },
    submitApprove() {
      // 标志变量，初始值为 true，表示可以提交
      let canSubmit = true

      // 在开始提交时将 isSubmitting 设置为 true
      this.isSubmitting = true

      // 提交逻辑
      this.$refs.formRef
        .validateFields()
        .then(() => {
          // 表单校验通过，执行提交逻辑

          // 使用之前创建的 Axios 实例进行请求
          let api = 'approve'

          // 提取各列表中的id数据
          let approverIds = this.approverList.map((approver) => approver.id)
          if (approverIds.length === 0) {
            this.showModal('error', '请添加上级审批人')
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          let financeApproverIds = this.financeApproverList.map(
            (approver) => approver.id
          )
          if (financeApproverIds.length === 0) {
            this.showModal('error', '请添加财务审批人')
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          let ccApproverIds = this.ccApproverList.map((approver) => approver.id)

          // 合并参数
          let requestData = {
            formState: {
              ...this.formState,
              detailInfo: this.formState.detailInfo.map((detail) => {
                const isInvalidInvoice = detail.invoiceFileList.some(
                  (invoice) =>
                    !invoice.response || invoice.response.code !== 200
                )
                const isInvalidAttachment = detail.attachmentFileList.some(
                  (attachment) =>
                    !attachment.response || attachment.response.code !== 200
                )

                if (isInvalidInvoice || isInvalidAttachment) {
                  // 弹出提示信息
                  this.$message.error('发票或附件上传失败，请检查并重新上传')
                  // 设置标志为 false，表示不可提交
                  canSubmit = false
                  // 返回原始 detail 对象，不做更改
                  return detail
                }

                return {
                  ...detail,
                  invoiceFileList: detail.invoiceFileList.map(
                    (invoice) => invoice.response.data.media_id
                  ),
                  attachmentFileList: detail.attachmentFileList.map(
                    (attachment) => attachment.response.data.media_id
                  ),
                }
              }),
            },
            approverList: approverIds,
            financeApproverList: financeApproverIds,
            ccApproverList: ccApproverIds,
          }

          // 如果标志为 false，表示有不符合条件的情况，不执行后续提交逻辑
          if (!canSubmit) {
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          // 其他提交逻辑...
          this.$http[api](requestData)
            .then((res) => {
              // 在提交完成时将 isSubmitting 设置为 false
              this.isSubmitting = false

              if (res.status != 200) return
              else if (res.data.code != 200)
                this.$message({
                  type: 'error',
                  message: res.data.msg,
                  showClose: true,
                })
              else {
                this.clearData()
                this.$message({
                  type: 'success',
                  message: `已提交`,
                  showClose: true,
                })
              }
            })
            .finally(() => {
              // this.state.loading = false
            })
        })
        .catch((err) => {
          // 在表单校验失败时将 isSubmitting 设置为 false
          this.isSubmitting = false

          // 表单校验失败，可以给出相应的提示
          console.error(err)
          console.error('表单填写有误，请检查并修改')
          this.showModal('error', '表单填写有误，请检查并修改')
        })
    },
    getApprove() {
      // 使用之前创建的 Axios 实例进行请求
      let api = 'get_approvers' // 注意这里使用了 get_approvers

      // 提交逻辑...

      // 调用 get_approvers 接口
      this.$http[api]()
        .then((res) => {
          if (res.status !== 200) return

          const { direct_supervisor, finance_approver, cc_approver } = res.data

          // 检查 directSupervisor 是否为数组且不为空
          if (
            Array.isArray(direct_supervisor) &&
            direct_supervisor.length > 0
          ) {
            // 将 directSupervisor 的元素添加到 approverList
            this.approverList.push(...direct_supervisor)
          } else {
            console.warn('Direct Supervisor list is empty or invalid')
          }

          // 检查 financeApprover 是否为数组且不为空
          if (Array.isArray(finance_approver) && finance_approver.length > 0) {
            // 将 finance_approver 的元素添加到 financeApproverList
            this.financeApproverList.push(...finance_approver)
          } else {
            console.warn('Finance Approver list is empty or invalid')
          }

          // 检查 ccApprover 是否为数组且不为空
          if (Array.isArray(cc_approver) && cc_approver.length > 0) {
            // 将 finance_approver 的元素添加到 financeApproverList
            this.ccApproverList.push(...cc_approver)
          } else {
            console.warn('Cc Approver list is empty or invalid')
          }
        })
        .catch((error) => {
          // 处理错误
          console.error('Error fetching approvers:', error)
          this.$message({
            type: 'error',
            message: '获取审批人信息失败',
            showClose: true,
          })
        })
        .finally(() => {
          // this.state.loading = false
        })
    },
    cancel() {
      // 取消逻辑
      // 这里可以处理取消操作，例如返回上一页
    },
    handleChange() {
      console.log(this.formState)
    },
    beforeUpload(file) {
      // 在上传之前的处理逻辑
      console.log('beforeUpload', file)
      return true // 返回 false 可以取消上传
    },
    handleUploadSuccess(response, file) {
      // 文件上传成功的处理
      console.log('handleUploadSuccess:', response, file)
    },
    handleUploadError(error, file) {
      // 文件上传失败的处理
      console.error('handleUploadError:', error, file)
    },
    handleInvoiceChange(index, { file, fileList, event }) {
      // 发票状态改变时的处理逻辑
      console.log('handleChange', file, fileList)

      if (file.status === 'removed') {
        const { code, data } = file.response
        if (code === RESPONSE_CODE.SUCCESS) {
          const { number } = data
          if (number) {
            this.invoiceNumberList = this.invoiceNumberList.filter(
              (item) => item !== number
            )
          }
        }
      } else if (file.status === 'done') {
        // 处理上传成功的逻辑
        this.handleSuccess(file.response, index, { file, fileList, event })
        this.formState.detailInfo[index].invoiceFileList = []
      } else if (file.status === 'error') {
        // 处理上传失败的逻辑
        // console.log('handleChange', JSON.stringify(file.error, null, 2))
        this.handleError(file.error, file)

        // TO DO 失败后从列表删除暂未实现
        // // 查找出错的文件在 fileList 中的索引
        // const index = this.invoiceFileList.findIndex(
        //   (item) => item.uid === file.uid
        // )

        // // 如果找到了对应的索引，从 fileList 中移除该文件
        // if (index !== -1) {
        //   this.invoiceFileList.splice(index, 1)
        // }
      }

      // 更新文件列表
      this.formState.detailInfo[index].invoiceFileList = [...fileList]
    },
    handleAttachmentChange(index, info) {
      // 附件状态改变时的处理逻辑
      console.log('handleChange', info.file, info.fileList)

      if (info.file.status === 'done') {
        // 处理上传成功的逻辑
        this.handleSuccess(info.file.response, index, info)
      } else if (info.file.status === 'error') {
        // 处理上传失败的逻辑
        this.handleError(info.file.error, info.file)
      }

      // 更新文件列表
      this.formState.detailInfo[index].attachmentFileList = [...info.fileList]
    },
    deleteLastAttachment(index) {
      // 删除最后一个附件
      if (
        this.formState.detailInfo[index] &&
        this.formState.detailInfo[index].attachmentFileList.length > 0
      ) {
        // 找到要删除的 attachmentFileList
        // 删除最后一个元素
        this.formState.detailInfo[index].attachmentFileList.pop()
      }
    },
    deleteLastInvoice(index) {
      // 删除最后一个附件
      const lastDetailInfo =
        this.formState.detailInfo[this.formState.detailInfo.length - 1]
      if (lastDetailInfo && lastDetailInfo.attachmentFileList.length > 0) {
        lastDetailInfo.attachmentFileList.splice(index, 1)
      }
    },
    handleSuccess(response, index, info) {
      // 上传成功时的处理逻辑

      // 2024.02.29 突然发现的魔鬼bug 应该不是正常的功能需要
      // this.deleteLastAttachment(index)

      // 根据后端返回的 code 进行不同的逻辑处理
      const { code, data } = response
      if (code === RESPONSE_CODE.SUCCESS) {
        const { number } = data
        if (number) {
          if (this.invoiceNumberList.includes(number)) {
            this.showModal('warning', '重复选择发票，请检查')
            response.code = 441
          } else {
            this.invoiceNumberList.push(number)
            const successMessage = RESPONSE_MESSAGES[code](number)
            this.showModal('success', successMessage)
          }
        }
      } else {
        const errorMessage = RESPONSE_MESSAGES[code] || '文件上传失败'
        this.showModal('error', errorMessage)
      }
    },
    handleError(error, file) {
      // 上传失败时的处理逻辑
      console.log('handleError', error, file)

      // 根据状态码进行不同逻辑处理
      if (error.status === 401) {
        this.showModal('error', '上传失败，需要登录')
      } else if (error.status === 400) {
        this.showModal('error', '上传失败，错误类型为 400')
      } else {
        this.showModal('error', `上传失败，状态码：${error.status}`)
      }
    },
    showModal(type, content) {
      // 根据不同类型显示不同样式的模态框
      switch (type) {
        case 'info':
          Modal.info({
            title: '提示',
            content: content,
          })
          break
        case 'warning':
          Modal.warn({
            title: '提示',
            content: content,
          })
          break
        case 'success':
          // 初始化 config 有问题, 待修改
          message.config({
            duration: 2,
            maxCount: 3,
          })
          message.success({
            content: content,
          })
          break
        case 'error':
          Modal.error({
            title: '失败',
            content: content,
          })
          break
        default:
          break
      }
    },
    async getConfigSignature(url) {
      try {
        // 后端接口地址为 /api/wecom_signature
        const response = await axios.get(
          this.$store.state.baseUrl + '/wecom_signature',
          {
            params: { url },
          }
        )
        const configSignature = response.data.data

        return {
          timestamp: configSignature.timestamp,
          nonceStr: configSignature.noncestr, // 使用 noncestr 字段
          signature: configSignature.signature,
        }
      } catch (error) {
        console.error('获取微信 JS-SDK 配置信息失败：', error)
      }
    },
    async getAgentConfigSignature(url) {
      try {
        // 后端接口地址为 /api/agent_signature
        const response = await axios.get(
          this.$store.state.baseUrl + '/agent_signature',
          {
            params: { url },
          }
        )
        const agentConfigSignature = response.data.data

        return {
          timestamp: agentConfigSignature.timestamp,
          nonceStr: agentConfigSignature.noncestr,
          signature: agentConfigSignature.signature,
        }
      } catch (error) {
        console.error('获取第三方应用签名失败：', error)
      }
    },
    async initWechatSDK() {
      try {
        // 注册 @wecom/jssdk，提供额外的第三方应用身份信息
        ww.register({
          beta: true,
          debug: false,
          corpId: this.$store.state.corpId,
          agentId: this.$store.state.agentId,
          jsApiList: [
            'selectEnterpriseContact',
            'openUserProfile',
            'checkJsApi',
          ],
          getConfigSignature: this.getConfigSignature,
          getAgentConfigSignature: this.getAgentConfigSignature,
        })
      } catch (error) {
        console.error('初始化微信 JS-SDK 失败：', error)
      }
    },
    thirdPartyOpenPage() {
      ww.thirdPartyOpenPage({
        oaType: '10001',
        templateId: '98159fdf238a3efdd361a5e8240e95b0_101585584',
        thirdNo: 'thirdNo1',
        extData: {
          fieldList: [
            {
              type: 'text',
              title: '采购类型',
              value: '市场活动',
            },
            {
              type: 'link',
              title: '订单链接',
              value: 'https://work.weixin.qq.com',
            },
          ],
        },
        success(res) {
          alert(JSON.stringify(res))
        },
      })
    },
    openContactSelectorSingle(level) {
      console.log('openContactSelectorSingle')

      // // 已选择过的用户ID列表
      // const selectedUserIds = this.approverList.map((user) => user.id)

      // 调用企业微信接口，打开通讯录选择器
      ww.selectEnterpriseContact({
        fromDepartmentId: -1,
        mode: 'single', // 单选
        type: ['user'],
        // selectedUserIds: selectedUserIds,
        success: (res) => {
          // 处理选择成功的逻辑
          const selectedUser = res.result.userList && res.result.userList[0]

          // 检查并添加到相应的列表中
          if (selectedUser) {
            if (level === 'superior') {
              // 添加上级审批人
              const existsInApproverList = this.approverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.approverList.push(approver)
              } else {
                this.showModal('warning', '上级审批人已存在')
              }
            } else if (level === 'finance') {
              // 添加财务审批人
              const existsInFinanceApproverList = this.financeApproverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInFinanceApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.financeApproverList.push(approver)
              } else {
                this.showModal('warning', '财务审批人已存在')
              }
            } else if (level === 'cc') {
              // 添加抄送人
              const existsInCcApproverList = this.ccApproverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInCcApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.ccApproverList.push(approver)
              } else {
                this.showModal('warning', '抄送人已存在')
              }
            }
          }
        },
        fail: (err) => {
          // 失败回调
          console.error('选择审批人失败:', err)

          try {
            // 尝试捕获异常
            if (err.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择审批人失败:', err)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
        cancel: (res) => {
          // 取消回调
          try {
            // 尝试捕获异常
            if (res.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择审批人失败:', res)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
      }).catch(() => {})
    },
    openContactSelectorDepartment(level) {
      console.log('openContactSelectorDepartment')

      // 调用企业微信接口，打开通讯录选择器
      ww.selectEnterpriseContact({
        fromDepartmentId: -1,
        mode: 'single', // 单选
        type: ['department'],
        success: (res) => {
          // 处理选择成功的逻辑
          this.formState.baseInfo.infoDepartment =
            res.result.departmentList && res.result.departmentList[0]
          this.formState.baseInfo.infoDepartmentName =
            this.formState.baseInfo.infoDepartment.name

          console.log(res)
          console.log(this.formState.baseInfo.infoDepartment)
        },
        fail: (err) => {
          // 失败回调
          console.error('选择部门失败:', err)
          // 失败回调
          try {
            // 尝试捕获异常
            if (err.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择部门失败:', err)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
        cancel: (res) => {
          // 取消回调
          try {
            // 尝试捕获异常
            if (res.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择部门失败:', res)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
      }).catch(() => {})
    },
    removeApprover(index) {
      // 移除上级审批人
      this.approverList.splice(index, 1)
    },
    removeFinanceApprover(index) {
      // 移除财务审批人
      this.financeApproverList.splice(index, 1)
    },
    removeCcApprover(index) {
      // 移除抄送人
      this.ccApproverList.splice(index, 1)
    },
    isExceedingFileLimit() {
      let totalFiles = 0

      // 23.12.29 发票合并提交后，不再计算 invoiceFileList 的文件数量
      // this.formState.detailInfo.forEach((detail) => {
      //   totalFiles += detail.invoiceFileList.length
      // })

      // 计算 attachmentFileList 的文件数量
      this.formState.detailInfo.forEach((detail) => {
        totalFiles += detail.attachmentFileList.length
      })

      // 检查总文件数量是否大于最大文件数量限制
      return totalFiles >= this.maxFileCountLimit
    },
  },
  mounted() {
    this.initWechatSDK()
    this.getApprove()
  },
}
</script>

<style scoped>
.page-container {
  max-width: 520px;
  margin: 0 auto;
  /* display: flex; */
  /* flex-direction: column; */
  position: relative;
  min-height: calc(
    100vh - 58px
  ); /* 计算剩余高度，确保内容撑满整个视口减去底部区域高度 */
}

.info-card,
.detail-card {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.card-header {
  font-size: 12px;
  text-align: left;
}

.content {
  background-color: #f5f5f5;
  padding: 15px;
  min-height: calc(100vh - 58px);
  padding-bottom: calc(58px + 15px); /* 让出底部区域高度 */
  /* margin-bottom: -58px; 通过负的 margin-bottom 来抵消底部区域的空白，实现阴影效果延伸 */
}
/* :where(
    .css-dev-only-do-not-override-11vqlyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-11vqlyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
} */

/* @media (max-width: 575px)
:where(.css-dev-only-do-not-override-1qb1s0s).ant-form .ant-form-item {
    flex-wrap: wrap;
} */
.expense-form {
  background-color: #f5f5f5;
  /* background-color: #ffffff; */
  /* padding: 10px; */
  /* border-radius: 8px; */
}

.expense-baseinfo {
  padding: 10px;
  border-radius: 8px;
}

/* .expense-header { */
/* display: flex; */
/* justify-content: space-between; */
/* align-items: baseline; */
/* background-color: #f5f5f5; 添加背景色 */
/* padding: 10px; 添加内边距 */
/* margin-left: -10px; */
/* margin-right: -10px; */
/* } */

.expense-title {
  font-size: 12px;
}

.expense-detail-container {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.expense-header {
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expense-detail {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.expense-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  background-color: #f5f5f5;
  padding: 10px;
}

.expense-detail-title {
  font-size: 12px;
}

.expense-divider {
  font-size: 12px; /* 调整字体大小 */
  border-block-start: 0 rgb(5, 5, 5, 0.1);
}

.add-detail-btn {
  margin-top: 10px;
}

.footer {
  /* margin-top: 20px; */
  /* text-align: center; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px; /* 设置底部区域高度 */
  background-color: #fff;
  box-shadow: 0px -5px 5px -5px #8f8f8f; /* 添加底部阴影效果，可根据需要调整 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 12px; /* 两个按钮之间的间隔 */
}

.button-container a-button {
  margin-right: 12px;
}

/* 修改 .ant-card-meta-avatar 的 padding-inline-end 样式 */
:deep(.ant-card-meta-avatar) {
  padding-inline-end: 5px;
}

/* 取消 .ant-card-actions 的 border-top 样式 */
:deep(.ant-card-actions) {
  border-top: none;
}

.custom-plus-btn {
  color: #267ef0;
  background-color: #dcebfd;
  padding: 15px;
  border-radius: 15%;
  cursor: pointer;
}

/* :deep(.ant-timeline-item) { */
/* display: flex; */
/* flex-direction: column; 垂直布局，内容在头部下面 */
/* } */

/* :deep(.ant-timeline-item-head), */
/* :deep(.ant-timeline-item-content) { */
/* flex: 1; 将头部和内容平均占据可用空间 */
/* } */

/* TODO 未实现 禁用上传列表删除按钮悬浮触发 */
/* 显示删除按钮 */
/* .ant-upload-list-item:hover .ant-upload-list-item-actions {
  opacity: 1;
}
:deep(.ant-upload-list-item:hover) {
  opacity: 1;
} */
</style>
