<template>
  <div class="page-container">
    <!-- 页头（留空） -->
    <div class="header"></div>
    <ConfigProvider componentSize="large" :theme="configTheme">
      <div class="content">
        <div v-if="selectedFunction === ''" class="button-container">
          <a-button type="primary" @click="selectFunction('viewRecords')">
            查看记录
          </a-button>

          <a-button type="primary" @click="selectFunction('uploadInvoice')">
            回传发票
          </a-button>

          <a-button type="primary" @click="selectFunction('submitApproval')">
            提交审批
          </a-button>
        </div>
        <div
          v-if="
            selectedFunction === 'viewRecords' ||
            selectedFunction === 'uploadInvoice'
          "
        >
          <div class="filter-container">
            <el-select
              v-model="selectedApprovalStatus"
              placeholder="审批状态"
              size="default"
              style="width: 40%; margin-right: 16px"
              @change="searchData"
              :disabled="selectedFunction !== 'viewRecords'"
            >
              <el-option label="审批状态" value="0">全部</el-option>
              <el-option label="审批中" value="1" />
              <el-option label="已通过" value="2" />
              <el-option label="已驳回" value="3" />
              <el-option label="已撤销" value="4" />
              <el-option label="通过后撤销" value="6" />
              <el-option label="已删除" value="7" />
              <el-option label="已支付" value="10" />
            </el-select>

            <el-select
              v-model="selectedInvoiceStatus"
              @select="resetHasMore"
              placeholder="发票状态"
              size="default"
              style="width: 40%"
              @change="searchData"
              :disabled="selectedFunction !== 'viewRecords'"
            >
              <el-option label="发票状态" value="0">全部</el-option>
              <el-option label="未回传发票" value="1" />
              <el-option label="已回传发票" value="2" />
            </el-select>

            <a-divider class="filter-divider" />
          </div>
          <el-dialog
            v-model="dialogVisible"
            title="回传发票"
            width="500"
            align-center
          >
            <a-upload
              accept="image/jpeg,image/png,application/pdf"
              :action="paymentUploadInvoiceUrl"
              :before-upload="beforeUpload"
              :headers="{
                Authorization: 'Bearer ' + $store.state.accessToken,
              }"
              :show-upload-list="{
                showRemoveIcon: true,
              }"
              v-model:file-list="invoiceList"
              @change="handleInvoiceChange(index, $event)"
            >
              <a-button>
                <CloudUploadOutlined />
                上传发票
              </a-button>
              <template #itemRender="{ file, actions }">
                <div
                  style="max-width: 100%; display: inline-block; line-height: 1"
                >
                  <a-spin
                    v-if="file.status === 'uploading'"
                    :indicator="LoadingOutlined"
                  />

                  <ExclamationCircleOutlined
                    v-if="
                      file.status === 'error' ||
                      (file.response && file.response.code !== 200)
                    "
                    style="
                      color: red;
                      margin-right: 8px;
                      vertical-align: middle;
                    "
                  />
                  <span
                    :style="{
                      color: file.status === 'error' ? 'red' : '',
                      display: 'inline-block',
                      maxWidth: '80%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      verticalAlign: 'middle',
                    }"
                    >{{ file.name }}</span
                  >
                  <a
                    href="javascript:;"
                    @click="actions.remove"
                    style="margin-left: 8px; vertical-align: middle"
                    >删除</a
                  >
                </div>
              </template>
            </a-upload>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">返回</el-button>
                <el-button
                  type="primary"
                  :disabled="disableConfirm"
                  @click="confirmInvoice"
                >
                  确认
                </el-button>
              </div>
            </template>
          </el-dialog>
          <el-dialog
            v-model="isShowPreview"
            title="预览发票"
            align-center
            :width="dialogWidth"
          >
            <template v-if="isShowPreview">
              <div v-if="isPdf" class="pdf-container">
                <iframe
                  :src="pdfViewerUrl"
                  width="100%"
                  :height="pdfHeight"
                  frameborder="0"
                ></iframe>
              </div>
              <div v-else-if="isImage" class="image-container">
                <img
                  :src="fileUrl"
                  alt="Image"
                  style="
                    max-width: 100%;
                    max-height: calc(70vh - 20px);
                    object-fit: contain;
                  "
                />
              </div>
              <div v-else>
                <p>不支持的文件类型或没有可用数据。</p>
              </div>
            </template>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="isShowPreview = false">返回</el-button>
                <el-button
                  type="primary"
                  :disabled="!canPrint"
                  @click="printFile"
                >
                  打印
                </el-button>
              </div>
            </template>
          </el-dialog>
          <div v-infinite-scroll="pageData">
            <el-card
              v-for="(record, index) in records"
              :key="index"
              shadow="hover"
              style="margin-bottom: 5px"
              ><el-descriptions
                class="card-descriptions"
                :title="`${record.submitter_name}的付款申请`"
                :column="1"
                size="default"
                border
              >
                <template #extra>
                  <div v-if="record.need_invoice === 1">
                    <el-button @click="showDialog(record, index)" type="primary"
                      >回传发票</el-button
                    >
                  </div>
                  <div v-else-if="record.need_invoice === 0">
                    <el-button @click="showDialog(record, index)" type="primary"
                      >预览发票</el-button
                    >
                  </div>
                </template>
                <el-descriptions-item label="付款类型" min-width="80px">
                  {{ record.payment_category }}
                </el-descriptions-item>
                <el-descriptions-item label="付款金额" min-width="80px">
                  {{ record.payment_amount }}
                </el-descriptions-item>
                <el-descriptions-item label="审批时间">
                  {{ record.apply_time }}
                </el-descriptions-item>
                <el-descriptions-item label="付款事由">
                  {{ record.payment_reason }}
                </el-descriptions-item>
              </el-descriptions></el-card
            >
            <div
              v-if="!hasMore & (records.length !== 0)"
              style="margin-top: 8px"
            >
              <el-text>没有更多了~</el-text>
            </div>
          </div>

          <el-empty description="没有数据了哦~" v-if="records.length === 0" />
        </div>
        <div v-else-if="selectedFunction === 'submitApproval'">
          <!-- 提交付款申请 -->
          <!-- 正文 -->
          <!-- 付款表单 -->
          <a-form
            :model="formState"
            class="payment-form"
            :label-col="labelCol"
            labelAlign="right"
            ref="formRef"
          >
            <!-- 基本信息卡片 -->
            <a-card
              class="info-card"
              :head-style="{
                fontSize: '12px',
                textAlign: 'left',
                minHeight: '40px',
              }"
              :body-style="{ padding: '10px' }"
            >
              <template #title>
                <span class="card-title">基本信息</span>
              </template>
              <a-form-item
                label="付款类别"
                :name="['baseInfo', 'infoType']"
                :rules="[{ required: true, message: '请选择付款类别' }]"
              >
                <a-select
                  v-model:value="formState.baseInfo.infoType"
                  placeholder="请选择"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                >
                  <a-select-option value="复印社返款"
                    >复印社返款</a-select-option
                  >
                  <a-select-option value="物品采购">物品采购</a-select-option>
                  <a-select-option value="业务退款">业务退款</a-select-option>
                  <a-select-option value="第3方费用">第3方费用</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>

              <!-- 采购用途、规格型号功能(详细)、数量、样品图片 -->
              <a-form-item
                v-if="formState.baseInfo.infoType === '物品采购'"
                label="采购用途"
                :name="['procureInfo', 'purchasePurpose']"
                :rules="[{ required: true, message: '请输入采购用途' }]"
              >
                <a-input
                  v-model:value="formState.procureInfo.purchasePurpose"
                  placeholder="请输入"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                v-if="formState.baseInfo.infoType === '物品采购'"
                label="规格型号"
                :name="['procureInfo', 'purchaseDetails']"
                :rules="[
                  { required: true, message: '请输入规格型号功能(详细)' },
                ]"
              >
                <a-textarea
                  v-model:value="formState.procureInfo.purchaseDetails"
                  placeholder="请输入规格型号功能(详细)"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                v-if="formState.baseInfo.infoType === '物品采购'"
                label="数量"
                :name="['procureInfo', 'purchaseQuantity']"
                :rules="[{ required: true, message: '请输入数量' }]"
              >
                <a-input
                  v-model:value="formState.procureInfo.purchaseQuantity"
                  placeholder="请输入"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                v-if="formState.baseInfo.infoType === '物品采购'"
                label="样品图片"
                :name="['procureInfo', 'sampleImage']"
                :rules="[{ required: true, message: '请上传样品图片' }]"
              >
                <a-upload
                  accept="image/jpeg,image/png"
                  :action="uploadAttachmentUrl"
                  :before-upload="beforeUpload"
                  :file-list="formState.procureInfo.sampleImage"
                  :headers="{
                    Authorization: 'Bearer ' + $store.state.accessToken,
                  }"
                  @change="handleSampleImageChange(index, $event)"
                >
                  <a-button :disabled="isExceedingFileLimit()">
                    <CloudUploadOutlined />
                    添加图片
                  </a-button>
                  <template #itemRender="{ file, actions }">
                    <div
                      style="
                        max-width: 100%;
                        display: inline-block;
                        line-height: 1;
                      "
                    >
                      <a-spin
                        v-if="file.status === 'uploading'"
                        :indicator="LoadingOutlined"
                      />
                      <ExclamationCircleOutlined
                        v-if="
                          file.status === 'error' ||
                          (file.response && file.response.code !== 200)
                        "
                        style="
                          color: red;
                          margin-right: 8px;
                          vertical-align: middle;
                        "
                      />

                      <span
                        :style="{
                          color: file.status === 'error' ? 'red' : '',
                          display: 'inline-block',
                          maxWidth: '80%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }"
                        >{{ file.name }}</span
                      >
                      <a
                        href="javascript:;"
                        @click="actions.remove"
                        style="margin-left: 8px; vertical-align: middle"
                        >删除</a
                      >
                    </div>
                  </template>
                </a-upload>
              </a-form-item>

              <!-- 付款事由 -->
              <a-form-item
                v-else
                label="付款事由"
                :name="['baseInfo', 'infoReason']"
                :rules="[{ required: true, message: '请输入付款事由' }]"
              >
                <a-textarea
                  v-model:value="formState.baseInfo.infoReason"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                label="付款金额"
                :name="['baseInfo', 'paymentAmount']"
                :rules="[
                  { type: 'number', required: true, message: '请输入付款金额' },
                ]"
              >
                <a-input-number
                  v-model:value="formState.baseInfo.paymentAmount"
                  :step="0.01"
                  :min="0"
                  :max="99999999"
                  :controls="false"
                  addon-after="元"
                  placeholder="超过两千元必须再添加杜总审批"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                label="付款方式"
                :name="['baseInfo', 'paymentMethod']"
                :rules="[{ required: true, message: '请选择付款方式' }]"
              >
                <a-select
                  v-model:value="formState.baseInfo.paymentMethod"
                  placeholder="请选择"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                >
                  <a-select-option value="微信">微信</a-select-option>
                  <a-select-option value="公对公">公对公</a-select-option>
                  <a-select-option value="银行卡">银行卡</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                label="付款日期"
                :name="['baseInfo', 'paymentDate']"
                :rules="[{ required: true, message: '请选择付款日期' }]"
              >
                <a-date-picker
                  v-model:value="formState.baseInfo.paymentDate"
                  placeholder="请选择"
                  style="width: 100%; text-align: left"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                label="收款名称"
                :name="['baseInfo', 'payeeName']"
                :rules="[{ required: true, message: '请填写收款人全称' }]"
              >
                <a-input
                  v-model:value="formState.baseInfo.payeeName"
                  placeholder="请填写收款人全称"
                  @change="handleChange"
                />
              </a-form-item>

              <!-- 银行账号信息 -->
              <a-form-item label="银行账号">
                <a-input
                  v-model:value="formState.baseInfo.bankAccountNumber"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>

              <!-- 开户行信息 -->
              <a-form-item label="开户行">
                <a-input
                  v-model:value="formState.baseInfo.bankName"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item
                v-if="formState.baseInfo.infoType === '物品采购'"
                label="注意事项"
              >
                <a-textarea
                  placeholder="将此审批抄送给验收人，到货后验收人请将物品及验收单照片上传到此审批的（添加备注）中"
                  disabled="true"
                />
              </a-form-item>

              <a-form-item label="费用说明">
                <a-textarea
                  v-model:value="formState.baseInfo.costDescription"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>

              <a-form-item label="费用说明2">
                <a-textarea
                  v-model:value="formState.baseInfo.costDescription2"
                  placeholder="请填写"
                  @change="handleChange"
                />
              </a-form-item>
              <a-form-item label="添加附件">
                <a-upload
                  :action="uploadAttachmentUrl"
                  :before-upload="beforeUpload"
                  :file-list="formState.detailInfo[0].attachmentFileList"
                  :headers="{
                    Authorization: 'Bearer ' + $store.state.accessToken,
                  }"
                  @change="handleAttachmentChange(index, $event)"
                >
                  <a-button :disabled="isExceedingFileLimit()">
                    <CloudUploadOutlined />
                    添加附件
                  </a-button>
                  <template #itemRender="{ file, actions }">
                    <div
                      style="
                        max-width: 100%;
                        display: inline-block;
                        line-height: 1;
                      "
                    >
                      <a-spin
                        v-if="file.status === 'uploading'"
                        :indicator="LoadingOutlined"
                      />
                      <ExclamationCircleOutlined
                        v-if="
                          file.status === 'error' ||
                          (file.response && file.response.code !== 200)
                        "
                        style="
                          color: red;
                          margin-right: 8px;
                          vertical-align: middle;
                        "
                      />

                      <span
                        :style="{
                          color: file.status === 'error' ? 'red' : '',
                          display: 'inline-block',
                          maxWidth: '80%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }"
                        >{{ file.name }}</span
                      >
                      <a
                        href="javascript:;"
                        @click="actions.remove"
                        style="margin-left: 8px; vertical-align: middle"
                        >删除</a
                      >
                    </div>
                  </template>
                </a-upload>
              </a-form-item>
            </a-card>

            <!-- 审批流程卡片 -->
            <a-card
              class="detail-card"
              title="审批流程"
              :head-style="{
                fontSize: '12px',
                textAlign: 'left',
                minHeight: '40px',
              }"
              :body-style="{ padding: '10px' }"
            >
              <a-timeline
                mode="left"
                style="
                  /* display: flex; */
                  /* flex-direction: row; */
                  /* align-items: flex-start; */
                  /* text-align: left; */
                "
              >
                <!-- 上级审批时间线 -->
                <a-timeline-item>
                  <template #dot>
                    <!-- 自定义节点图标 -->
                    <div>
                      <AuditOutlined />
                    </div>
                  </template>
                  <!-- 上级审批内容 -->
                  <div style="text-align: left">
                    <!-- 上级审批标题 -->
                    <div
                      style="
                        font-weight: bold;
                        font-size: 14px;
                        /* margin-bottom: 10px; */
                        /* margin-top: 10px; */
                        margin: 10px 0px;
                      "
                    >
                      上级审批
                    </div>
                    <!-- 上级审批列表 -->
                    <a-space wrap :style="{ marginBottom: '5px' }">
                      <template
                        v-for="(user, index) in approverList"
                        :key="index"
                      >
                        <a-card
                          hoverable
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            /* margin-bottom: 10px; */
                            border-radius: 10px; /* 设置整体的圆角半径 */
                            overflow: hidden; /* 保证内容在圆角处不溢出 */
                          "
                          :bodyStyle="{ padding: '10px 5px' }"
                        >
                          <a-card-meta
                            :title="user.name"
                            style="align-items: center"
                          >
                            <template #avatar>
                              <a-avatar
                                shape="square"
                                :src="user.avatar"
                                v-if="user.avatar"
                              />
                              <a-avatar
                                shape="square"
                                style="background-color: #267ef0"
                                v-else
                                >{{ user.name.slice(-2) }}</a-avatar
                              >
                            </template>
                          </a-card-meta>
                          <template #actions>
                            <close-outlined
                              :style="{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }"
                              @click="() => removeApprover(index)"
                            />
                          </template>
                        </a-card>
                        <arrow-right-outlined />
                      </template>
                      <plus-outlined
                        class="custom-plus-btn"
                        @click="openContactSelectorSingle('superior')"
                      />
                    </a-space>
                  </div>
                </a-timeline-item>

                <!-- 财务审批时间线 -->
                <a-timeline-item>
                  <template #dot>
                    <!-- 自定义节点图标 -->
                    <div>
                      <DollarCircleOutlined />
                    </div>
                  </template>
                  <!-- 财务审批内容 -->
                  <div style="text-align: left">
                    <!-- 财务审批标题 -->
                    <div
                      style="
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 10px;
                      "
                    >
                      财务审批
                    </div>
                    <!-- 财务审批列表 -->
                    <a-space wrap :style="{ marginBottom: '5px' }">
                      <template
                        v-for="(financeUser, index) in financeApproverList"
                        :key="index"
                      >
                        <a-card
                          hoverable
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-radius: 10px;
                            overflow: hidden;
                          "
                          :bodyStyle="{ padding: '10px 5px' }"
                        >
                          <a-card-meta
                            :title="financeUser.name"
                            style="align-items: center"
                          >
                            <template #avatar>
                              <a-avatar
                                shape="square"
                                :src="financeUser.avatar"
                                v-if="financeUser.avatar"
                              />
                              <a-avatar
                                shape="square"
                                style="background-color: #267ef0"
                                v-else
                                >{{ financeUser.name.slice(-2) }}</a-avatar
                              >
                            </template>
                          </a-card-meta>
                          <template #actions>
                            <close-outlined
                              :style="{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }"
                              @click="() => removeFinanceApprover(index)"
                            />
                          </template>
                        </a-card>
                        <arrow-right-outlined />
                      </template>
                      <plus-outlined
                        class="custom-plus-btn"
                        @click="openContactSelectorSingle('finance')"
                      />
                    </a-space>
                  </div>
                </a-timeline-item>

                <!-- 抄送人时间线 -->
                <a-timeline-item>
                  <template #dot>
                    <!-- 自定义节点图标 -->
                    <div>
                      <SendOutlined />
                    </div>
                  </template>
                  <!-- 抄送人内容 -->
                  <div style="text-align: left">
                    <!-- 抄送人标题 -->
                    <div
                      style="
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 10px;
                      "
                    >
                      抄送人
                    </div>
                    <!-- 抄送人列表 -->
                    <a-space wrap :style="{ marginBottom: '5px' }">
                      <template
                        v-for="(ccUser, index) in ccApproverList"
                        :key="index"
                      >
                        <a-card
                          hoverable
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-radius: 10px;
                            overflow: hidden;
                          "
                          :bodyStyle="{ padding: '10px 5px' }"
                        >
                          <a-card-meta
                            :title="ccUser.name"
                            style="align-items: center"
                          >
                            <template #avatar>
                              <a-avatar
                                shape="square"
                                :src="ccUser.avatar"
                                v-if="ccUser.avatar"
                              />
                              <a-avatar
                                shape="square"
                                style="background-color: #267ef0"
                                v-else
                                >{{ ccUser.name.slice(-2) }}</a-avatar
                              >
                            </template>
                          </a-card-meta>
                          <template #actions>
                            <close-outlined
                              :style="{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }"
                              @click="() => removeCcApprover(index)"
                            />
                          </template>
                        </a-card>
                        <arrow-right-outlined />
                      </template>
                      <plus-outlined
                        class="custom-plus-btn"
                        @click="openContactSelectorSingle('cc')"
                      />
                    </a-space>
                  </div>
                </a-timeline-item>
              </a-timeline>
            </a-card>
          </a-form>
        </div>
      </div>

      <!-- 页脚 -->
      <div class="footer">
        <div class="footer-container">
          <a-button
            type="primary"
            :loading="isSubmitting"
            @click="submitApprove"
            :disabled="selectedFunction !== 'submitApproval'"
            >提交</a-button
          >
          <a-button @click="cancel">返回</a-button>
        </div>
      </div>
    </ConfigProvider>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Select,
  SelectOption,
  Input,
  DatePicker,
  Textarea,
  Button,
  ConfigProvider,
  Card,
  Upload,
  Modal,
  Space,
  Avatar,
  CardMeta,
  Timeline,
  TimelineItem,
  Spin,
  InputNumber,
  List,
  ListItem,
  Divider,
  Row,
  Col,
  SelectOptGroup,
  notification,
} from 'ant-design-vue'
import {
  CloudUploadOutlined,
  CloseOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined,
  SendOutlined,
  DollarCircleOutlined,
  AuditOutlined,
  UploadOutlined,
  PictureOutlined,
  FilePdfOutlined,
  FileOutlined,
  UserOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'

import { h } from 'vue'

import * as ww from '@wecom/jssdk'
import axios from 'axios'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf'
// import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf'
// import { renderTextLayer } from 'pdfjs-dist'
import {
  WorkerMessageHandler,
  renderTextLayer,
} from 'pdfjs-dist/build/pdf.worker.min.mjs'
import { RESPONSE_CODE, RESPONSE_MESSAGES } from '@/responseConstants.js'
import printJS from 'print-js'

import { PDFDocument } from 'pdf-lib'

// import * as pdfjsLib from 'pdfjs-dist'

// 指定 worker 脚本路径
GlobalWorkerOptions.workerSrc = WorkerMessageHandler

export default {
  components: {
    AForm: Form,
    AFormItem: FormItem,
    ASelect: Select,
    ASelectOption: SelectOption,
    AInput: Input,
    ADatePicker: DatePicker,
    ATextarea: Textarea,
    AButton: Button,
    ConfigProvider: ConfigProvider,
    ACard: Card,
    AUpload: Upload,
    CloudUploadOutlined: CloudUploadOutlined,
    CloseOutlined: CloseOutlined,
    PlusOutlined: PlusOutlined,
    ASpace: Space,
    AAvatar: Avatar,
    ACardMeta: CardMeta,
    ArrowRightOutlined: ArrowRightOutlined,
    ATimeline: Timeline,
    ATimelineItem: TimelineItem,
    SendOutlined: SendOutlined,
    DollarCircleOutlined: DollarCircleOutlined,
    AuditOutlined: AuditOutlined,
    ASpin: Spin,
    ExclamationCircleOutlined: ExclamationCircleOutlined,
    AInputNumber: InputNumber,
    // AList: List,
    // AListItem: ListItem,
    ADivider: Divider,
    // ASelectOptGroup: SelectOptGroup,
    // ARow: Row,
    // ACol: Col,
    // UploadOutlined: UploadOutlined,
    // AIcon: Icon,
    // PictureOutlined: PictureOutlined,
    // FilePdfOutlined: FilePdfOutlined,
    // FileOutlined: FileOutlined,
  },
  data() {
    return {
      formState: {
        baseInfo: {
          infoType: undefined,
          infoReason: undefined,
          paymentAmount: undefined,
          paymentMethod: undefined,
          paymentDate: undefined,
          payeeName: undefined,
          bankAccountNumber: undefined,
          bankName: undefined,
          costDescription: undefined,
          costDescription2: undefined,
        },
        procureInfo: {
          purchasePurpose: undefined,
          purchaseDetails: undefined,
          purchaseQuantity: undefined,
          sampleImage: undefined,
        },
        detailInfo: [
          {
            invoiceFileList: [],
            attachmentFileList: [],
          },
        ],
      },
      maxFileCountLimit: 5, // 最大附件数，总计自建应用可提交6个附件，发票汇总word占用一个
      configTheme: {
        components: {
          Form: {
            screenXSMax: 0,
          },
        },
        token: { colorPrimary: '#2F7DCD' },
      },
      labelCol: { style: { width: '80px' } },
      uploadInvoiceUrl: this.$store.state.baseUrl + '/upload', // 上传发票的地址
      paymentUploadInvoiceUrl: this.$store.state.baseUrl + '/payment/upload', // 上传发票的地址
      uploadAttachmentUrl: this.$store.state.baseUrl + '/upload_media', // 上传附件的地址
      approverList: [], // 从企业微信获取的审批人列表
      financeApproverList: [],
      ccApproverList: [],
      LoadingOutlined: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      isSubmitting: false,
      showNoticeModal: false,
      unsubmittedApprovals: [],
      selectedFunction: '',
      selectedApprovalStatus: '0',
      selectedInvoiceStatus: '0',
      records: [],
      submitter: null,
      approvalId: null,
      applyTimeStart: null,
      applyTimeEnd: null,
      page: 1,
      perPage: 10,
      hasMore: true,
      dialogVisible: false,
      invoiceList: [],
      disableConfirm: true,
      isShowPreview: false,
      invoiceNumberList: [],
      // isPdf: false,
      // isImage: false,
      fileUrl: '',
      // dialogWidth: '80%',
      pdfHeight: '600px',
      isPdf: false,
      isImage: false,
      dataType: null,
    }
  },
  methods: {
    clearData() {
      this.formState = {
        baseInfo: {
          infoType: undefined,
          infoReason: undefined,
          paymentAmount: undefined,
          paymentMethod: undefined,
          paymentDate: undefined,
          payeeName: undefined,
          bankAccountNumber: undefined,
          bankName: undefined,
          costDescription: undefined,
          costDescription2: undefined,
        },
        procureInfo: {
          purchasePurpose: undefined,
          purchaseDetails: undefined,
          purchaseQuantity: undefined,
          sampleImage: undefined,
        },
        detailInfo: [
          {
            invoiceFileList: [],
            attachmentFileList: [],
          },
        ],
      }
      // this.approverList = []
      // this.financeApproverList = []
      // this.ccApproverList = []
      this.formState.detailInfo[0].invoiceFileList = []
    },
    submitApprove() {
      // 标志变量，初始值为 true，表示可以提交
      let canSubmit = true

      // 在开始提交时将 isSubmitting 设置为 true
      this.isSubmitting = true

      // 提交逻辑
      this.$refs.formRef
        .validateFields()
        .then(() => {
          // 表单校验通过，执行提交逻辑

          // 使用之前创建的 Axios 实例进行请求
          let api = 'payment_approve'

          // 提取各列表中的id数据
          let approverIds = this.approverList.map((approver) => approver.id)
          if (approverIds.length === 0) {
            this.showModal('error', '请添加上级审批人')
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          let financeApproverIds = this.financeApproverList.map(
            (approver) => approver.id
          )
          if (financeApproverIds.length === 0) {
            this.showModal('error', '请添加财务审批人')
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          let ccApproverIds = this.ccApproverList.map((approver) => approver.id)

          // 合并参数
          let requestData = {
            formState: {
              ...this.formState,
              detailInfo: this.formState.detailInfo.map((detail) => {
                const isInvalidInvoice = detail.invoiceFileList.some(
                  (invoice) =>
                    !invoice.response || invoice.response.code !== 200
                )
                const isInvalidAttachment = detail.attachmentFileList.some(
                  (attachment) =>
                    !attachment.response || attachment.response.code !== 200
                )

                if (isInvalidInvoice || isInvalidAttachment) {
                  // 弹出提示信息
                  this.$message.error('发票或附件上传失败，请检查并重新上传')
                  // 设置标志为 false，表示不可提交
                  canSubmit = false
                  // 返回原始 detail 对象，不做更改
                  return detail
                }

                return {
                  ...detail,
                  invoiceFileList: detail.invoiceFileList.map(
                    (invoice) => invoice.response.data.media_id
                  ),
                  attachmentFileList: detail.attachmentFileList.map(
                    (attachment) => attachment.response.data.media_id
                  ),
                }
              }),
              procureInfo: {
                ...this.formState.procureInfo,
                sampleImage: this.formState.procureInfo.sampleImage
                  ? this.formState.procureInfo.sampleImage.map((sample) => {
                      const isInvalidAttachment =
                        sample.response && sample.response.code !== 200

                      if (isInvalidAttachment) {
                        // 弹出提示信息
                        this.$message.error('附件上传失败，请检查并重新上传')
                        // 设置标志为 false，表示不可提交
                        canSubmit = false
                        // 返回原始 sample 对象，不做更改
                        return sample
                      }

                      // 返回包含更新后的 media_id 的新对象
                      return sample.response.data.media_id
                    })
                  : [],
              },
            },
            approverList: approverIds,
            financeApproverList: financeApproverIds,
            ccApproverList: ccApproverIds,
          }

          console.log(requestData)

          // 如果标志为 false，表示有不符合条件的情况，不执行后续提交逻辑
          if (!canSubmit) {
            // 在这里设置 isSubmitting 为 false
            this.isSubmitting = false
            return
          }

          // 其他提交逻辑...
          this.$http[api](requestData)
            .then((res) => {
              // 在提交完成时将 isSubmitting 设置为 false
              this.isSubmitting = false

              if (res.status != 200) return
              else if (res.data.code != 200)
                this.$message({
                  type: 'error',
                  message: res.data.msg,
                  showClose: true,
                })
              else {
                this.clearData()
                this.$message({
                  type: 'success',
                  message: `已提交`,
                  showClose: true,
                })
              }
            })
            .finally(() => {
              // this.state.loading = false
            })
        })
        .catch((err) => {
          // 在表单校验失败时将 isSubmitting 设置为 false
          this.isSubmitting = false

          // 表单校验失败，可以给出相应的提示
          console.error(err)
          console.error('表单填写有误，请检查并修改')
          this.showModal('error', '表单填写有误，请检查并修改')
        })
    },
    getApprove() {
      // 使用之前创建的 Axios 实例进行请求
      let api = 'get_approvers' // 注意这里使用了 get_approvers

      // 提交逻辑...

      let params = { type: 'payment' }

      // 调用 get_approvers 接口
      this.$http[api](params)
        .then((res) => {
          if (res.status !== 200) return

          const { direct_supervisor, finance_approver, cc_approver } = res.data

          // 检查 directSupervisor 是否为数组且不为空
          if (
            Array.isArray(direct_supervisor) &&
            direct_supervisor.length > 0
          ) {
            // 将 directSupervisor 的元素添加到 approverList
            this.approverList.push(...direct_supervisor)
          } else {
            console.warn('Direct Supervisor list is empty or invalid')
          }

          // 检查 financeApprover 是否为数组且不为空
          if (Array.isArray(finance_approver) && finance_approver.length > 0) {
            // 将 finance_approver 的元素添加到 financeApproverList
            this.financeApproverList.push(...finance_approver)
          } else {
            console.warn('Finance Approver list is empty or invalid')
          }

          // 检查 ccApprover 是否为数组且不为空
          if (Array.isArray(cc_approver) && cc_approver.length > 0) {
            // 将 finance_approver 的元素添加到 financeApproverList
            this.ccApproverList.push(...cc_approver)
          } else {
            console.warn('Cc Approver list is empty or invalid')
          }
        })
        .catch((error) => {
          // 处理错误
          console.error('Error fetching approvers:', error)
          this.$message({
            type: 'error',
            message: '获取审批人信息失败',
            showClose: true,
          })
        })
        .finally(() => {
          // this.state.loading = false
        })
    },
    cancel() {
      // 取消逻辑
      // 这里可以处理取消操作，例如返回上一页
      if (this.selectedFunction === '') window.close()
      else {
        this.selectedFunction = ''
        this.selectedApprovalStatus = '0'
        this.selectedInvoiceStatus = '0'
        this.records = []
        this.page = 1
        this.hasMore = true
        this.dialogVisible = false
        this.invoiceList = []
        this.approvalId = ''
      }
    },
    handleChange() {
      console.log(this.formState)
    },
    beforeUpload(file) {
      // 在上传之前的处理逻辑
      console.log('beforeUpload', file)
      return true // 返回 false 可以取消上传
    },
    handleUploadSuccess(response, file) {
      // 文件上传成功的处理
      console.log('handleUploadSuccess:', response, file)
    },
    handleUploadError(error, file) {
      // 文件上传失败的处理
      console.error('handleUploadError:', error, file)
    },
    handleInvoiceChange(index, { file, fileList, event }) {
      // 发票状态改变时的处理逻辑
      console.log('handleChange', file, fileList)
      console.log(index)

      if (file.status === 'removed') {
        const { code, data } = file.response
        if (code === RESPONSE_CODE.SUCCESS) {
          const { number } = data
          if (number) {
            this.invoiceNumberList = this.invoiceNumberList.filter(
              (item) => item !== number
            )
          }
        }
      } else if (file.status === 'done') {
        console.log('success')
        // 处理上传成功的逻辑
        this.handleSuccess(file.response, index, { file, fileList, event })
        this.formState.detailInfo[0].invoiceFileList = []
      } else if (file.status === 'error') {
        // 处理上传失败的逻辑
        // console.log('handleChange', JSON.stringify(file.error, null, 2))
        this.handleError(file.error, file)

        // TO DO 失败后从列表删除暂未实现
        // // 查找出错的文件在 fileList 中的索引
        // const index = this.invoiceFileList.findIndex(
        //   (item) => item.uid === file.uid
        // )

        // // 如果找到了对应的索引，从 fileList 中移除该文件
        // if (index !== -1) {
        //   this.invoiceFileList.splice(index, 1)
        // }
      }

      // 更新文件列表
      this.formState.detailInfo[0].invoiceFileList = [...fileList]

      console.log(this.invoiceList)
      if (this.invoiceList.length > 0) this.disableConfirm = false
      else this.disableConfirm = true
    },
    handleAttachmentChange(index, info) {
      // 附件状态改变时的处理逻辑
      console.log('handleChange', info.file, info.fileList)

      if (info.file.status === 'done') {
        // 处理上传成功的逻辑
        this.handleSuccess(info.file.response, index, info)
      } else if (info.file.status === 'error') {
        // 处理上传失败的逻辑
        this.handleError(info.file.error, info.file)
      }

      // 更新文件列表
      this.formState.detailInfo[0].attachmentFileList = [...info.fileList]
    },
    handleSampleImageChange(index, info) {
      // 附件状态改变时的处理逻辑
      console.log('handleChange', info.file, info.fileList)

      if (info.file.status === 'done') {
        // 处理上传成功的逻辑
        this.handleSuccess(info.file.response, index, info)
      } else if (info.file.status === 'error') {
        // 处理上传失败的逻辑
        this.handleError(info.file.error, info.file)
      }

      // 更新文件列表
      this.formState.procureInfo.sampleImage = [...info.fileList]
    },
    deleteLastAttachment(index) {
      // 删除最后一个附件
      if (
        this.formState.detailInfo[0] &&
        this.formState.detailInfo[0].attachmentFileList.length > 0
      ) {
        // 找到要删除的 attachmentFileList
        // 删除最后一个元素
        this.formState.detailInfo[0].attachmentFileList.pop()
      }
    },
    deleteLastInvoice(index) {
      // 删除最后一个附件
      const lastDetailInfo =
        this.formState.detailInfo[this.formState.detailInfo.length - 1]
      if (lastDetailInfo && lastDetailInfo.attachmentFileList.length > 0) {
        lastDetailInfo.attachmentFileList.splice(index, 1)
      }
    },
    handleSuccess(response, index, info) {
      // 上传成功时的处理逻辑

      // 根据后端返回的 code 进行不同的逻辑处理
      const { code, data } = response
      if (code === RESPONSE_CODE.SUCCESS) {
        const { number } = data
        if (number) {
          if (this.invoiceNumberList.includes(number)) {
            this.showModal('warning', '重复选择发票，请检查')
            response.code = 441
          } else {
            this.invoiceNumberList.push(number)
            const successMessage = RESPONSE_MESSAGES[code](number)
            this.showModal('success', successMessage)
          }
        }
      } else {
        const errorMessage = RESPONSE_MESSAGES[code] || '文件上传失败'
        this.showModal('error', errorMessage)
      }
    },
    handleError(error, file) {
      // 上传失败时的处理逻辑
      console.log('handleError', error, file)

      // 根据状态码进行不同逻辑处理
      if (error.status === 401) {
        this.showModal('error', '上传失败，需要登录')
      } else if (error.status === 400) {
        this.showModal('error', '上传失败，错误类型为 400')
      } else {
        this.showModal('error', `上传失败，状态码：${error.status}`)
      }
    },
    showModal(type, content) {
      // 根据不同类型显示不同样式的模态框
      switch (type) {
        case 'info':
          ElMessageBox.alert(content, {
            confirmButtonText: '确认',
            type: 'info',
            center: true,
          })
          break
        case 'warning':
          ElMessageBox.alert(content, {
            confirmButtonText: '确认',
            type: 'warning',
            center: true,
          })
          break
        case 'success':
          ElMessageBox.alert(content, {
            confirmButtonText: '确认',
            type: 'success',
            center: true,
          })
          break
        case 'error':
          ElMessageBox.alert(content, {
            confirmButtonText: '确认',
            type: 'error',
            center: true,
          })
          break
        default:
          break
      }
    },
    async getConfigSignature(url) {
      try {
        // 后端接口地址为 /api/wecom_signature
        const response = await axios.get(
          this.$store.state.baseUrl + '/wecom_signature',
          {
            params: { url },
          }
        )
        const configSignature = response.data.data

        return {
          timestamp: configSignature.timestamp,
          nonceStr: configSignature.noncestr, // 使用 noncestr 字段
          signature: configSignature.signature,
        }
      } catch (error) {
        console.error('获取微信 JS-SDK 配置信息失败：', error)
      }
    },
    async getAgentConfigSignature(url) {
      try {
        // 后端接口地址为 /api/agent_signature
        const response = await axios.get(
          this.$store.state.baseUrl + '/agent_signature',
          {
            params: { url },
          }
        )
        const agentConfigSignature = response.data.data

        return {
          timestamp: agentConfigSignature.timestamp,
          nonceStr: agentConfigSignature.noncestr,
          signature: agentConfigSignature.signature,
        }
      } catch (error) {
        console.error('获取第三方应用签名失败：', error)
      }
    },
    async initWechatSDK() {
      try {
        // 注册 @wecom/jssdk，提供额外的第三方应用身份信息
        ww.register({
          beta: true,
          debug: false,
          corpId: this.$store.state.corpId,
          agentId: this.$store.state.agentId,
          jsApiList: [
            'selectEnterpriseContact',
            'openUserProfile',
            'checkJsApi',
          ],
          getConfigSignature: this.getConfigSignature,
          getAgentConfigSignature: this.getAgentConfigSignature,
        })
      } catch (error) {
        console.error('初始化微信 JS-SDK 失败：', error)
      }
    },
    thirdPartyOpenPage() {
      ww.thirdPartyOpenPage({
        oaType: '10001',
        templateId: '98159fdf238a3efdd361a5e8240e95b0_101585584',
        thirdNo: 'thirdNo1',
        extData: {
          fieldList: [
            {
              type: 'text',
              title: '采购类型',
              value: '市场活动',
            },
            {
              type: 'link',
              title: '订单链接',
              value: 'https://work.weixin.qq.com',
            },
          ],
        },
        success(res) {
          alert(JSON.stringify(res))
        },
      })
    },
    openContactSelectorSingle(level) {
      console.log('openContactSelectorSingle')

      // // 已选择过的用户ID列表
      // const selectedUserIds = this.approverList.map((user) => user.id)

      // 调用企业微信接口，打开通讯录选择器
      ww.selectEnterpriseContact({
        fromDepartmentId: -1,
        mode: 'single', // 单选
        type: ['user'],
        // selectedUserIds: selectedUserIds,
        success: (res) => {
          // 处理选择成功的逻辑
          const selectedUser = res.result.userList && res.result.userList[0]

          // 检查并添加到相应的列表中
          if (selectedUser) {
            if (level === 'superior') {
              // 添加上级审批人
              const existsInApproverList = this.approverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.approverList.push(approver)
              } else {
                this.showModal('warning', '上级审批人已存在')
              }
            } else if (level === 'finance') {
              // 添加财务审批人
              const existsInFinanceApproverList = this.financeApproverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInFinanceApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.financeApproverList.push(approver)
              } else {
                this.showModal('warning', '财务审批人已存在')
              }
            } else if (level === 'cc') {
              // 添加抄送人
              const existsInCcApproverList = this.ccApproverList.some(
                (existingUser) => existingUser.id === selectedUser.id
              )
              if (!existsInCcApproverList) {
                const approver = {
                  id: selectedUser.id,
                  name: selectedUser.name,
                  avatar: selectedUser.avatar,
                }
                this.ccApproverList.push(approver)
              } else {
                this.showModal('warning', '抄送人已存在')
              }
            }
          }
        },
        fail: (err) => {
          // 失败回调
          console.error('选择审批人失败:', err)

          try {
            // 尝试捕获异常
            if (err.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择审批人失败:', err)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
        cancel: (res) => {
          // 取消回调
          try {
            // 尝试捕获异常
            if (res.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择审批人失败:', res)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
      }).catch(() => {})
    },
    openContactSelectorDepartment(level) {
      console.log('openContactSelectorDepartment')

      // 调用企业微信接口，打开通讯录选择器
      ww.selectEnterpriseContact({
        fromDepartmentId: -1,
        mode: 'single', // 单选
        type: ['department'],
        success: (res) => {
          // 处理选择成功的逻辑
          this.formState.baseInfo.infoDepartment =
            res.result.departmentList && res.result.departmentList[0]

          console.log(res)
          console.log(this.formState.baseInfo.infoDepartment)
        },
        fail: (err) => {
          // 失败回调
          console.error('选择部门失败:', err)
          // 失败回调
          try {
            // 尝试捕获异常
            if (err.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择部门失败:', err)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
        cancel: (res) => {
          // 取消回调
          try {
            // 尝试捕获异常
            if (res.errMsg.indexOf('selectEnterpriseContact:cancel') === -1) {
              // 仅当不是取消操作时输出错误
              console.error('选择部门失败:', res)
            }
          } catch (error) {
            // 异常捕获失败时的处理
            console.error('处理异常时出错:', error)
          }
        },
      }).catch(() => {})
    },
    removeApprover(index) {
      // 移除上级审批人
      this.approverList.splice(index, 1)
    },
    removeFinanceApprover(index) {
      // 移除财务审批人
      this.financeApproverList.splice(index, 1)
    },
    removeCcApprover(index) {
      // 移除抄送人
      this.ccApproverList.splice(index, 1)
    },
    isExceedingFileLimit() {
      let totalFiles = 0

      // 23.12.29 发票合并提交后，不再计算 invoiceFileList 的文件数量
      // this.formState.detailInfo.forEach((detail) => {
      //   totalFiles += detail.invoiceFileList.length
      // })

      // 计算 attachmentFileList 的文件数量
      this.formState.detailInfo.forEach((detail) => {
        totalFiles += detail.attachmentFileList.length
      })

      if (this.formState.procureInfo.sampleImage)
        totalFiles += this.formState.procureInfo.sampleImage.length

      // 检查总文件数量是否大于最大文件数量限制
      return totalFiles >= this.maxFileCountLimit
    },
    selectFunction(func) {
      this.selectedFunction = func
    },
    initData() {
      notification.config({
        maxCount: 1,
      })
    },
    loadData() {
      if (this.hasMore) {
        // 调用后端接口查询未补发票的审批
        let api = 'payment_list'
        let params = {
          submitter: this.submitter,
          approval_id: this.approvalId,
          apply_time_start: this.applyTimeStart,
          apply_time_end: this.applyTimeEnd,
          approval_status: this.selectedApprovalStatus,
          page: this.page,
          per_page: this.perPage,
          has_invoice: this.hasInvoice,
        }

        this.$http[api](params).then((res) => {
          if (res.status != 200) return
          else if (res.data.code != 200)
            this.$message({
              type: 'error',
              message: res.data.msg,
              showClose: true,
            })
          else {
            if (this.page === 1) this.records = res.data.data
            else this.records.push(...res.data.data)
            this.page += 1
            if (res.data.data.length < this.perPage) this.hasMore = false
          }
        })
      } else
        notification.open({
          message: `没有更多啦~`,
          placement: 'bottom',
          bottom: '70px',
        })
    },
    searchData() {
      const loadingInstance = ElLoading.service()

      this.resetHasMore()

      // 调用后端接口查询未补发票的审批
      let api = 'payment_list'
      let params = {
        submitter: this.submitter,
        approval_id: this.approvalId,
        apply_time_start: this.applyTimeStart,
        apply_time_end: this.applyTimeEnd,
        approval_status: this.selectedApprovalStatus,
        page: this.page,
        per_page: this.perPage,
        has_invoice: this.selectedInvoiceStatus,
      }

      console.log(params)

      this.$http[api](params).then((res) => {
        loadingInstance.close()

        if (res.status != 200) return
        else if (res.data.code != 200)
          this.$message({
            type: 'error',
            message: res.data.msg,
            showClose: true,
          })
        else {
          if (this.page === 1) this.records = res.data.data
          else this.records.push(...res.data.data)
          console.log(111, this.records)
          this.page += 1
          if (res.data.data.length < this.perPage) this.hasMore = false
        }
      })
    },
    pageData() {
      if (this.hasMore) {
        const loadingInstance = ElLoading.service()

        // 调用后端接口查询未补发票的审批
        let api = 'payment_list'
        let params = {
          submitter: this.submitter,
          approval_id: this.approvalId,
          apply_time_start: this.applyTimeStart,
          apply_time_end: this.applyTimeEnd,
          approval_status: this.selectedApprovalStatus,
          page: this.page,
          per_page: this.perPage,
          has_invoice: this.selectedInvoiceStatus,
        }

        this.$http[api](params).then((res) => {
          loadingInstance.close()

          if (res.status != 200) return
          else if (res.data.code != 200)
            this.$message({
              type: 'error',
              message: res.data.msg,
              showClose: true,
            })
          else {
            if (this.page === 1) this.records = res.data.data
            else this.records.push(...res.data.data)
            console.log(222, this.records)
            this.page += 1
            console.log(res.data.data.length, this.perPage)
            if (res.data.data.length < this.perPage) this.hasMore = false
          }
        })
      }
    },
    resetHasMore() {
      this.hasMore = true
      this.page = 1

      window.scrollTo(0, 0)
    },
    async showDialog(record, index) {
      const loadingInstance = ElLoading.service({ fullscreen: true })
      if (record.need_invoice === 1) {
        this.approvalId = record.approval_id
        this.invoiceList = []

        this.dialogVisible = true
        this.disableConfirm = true
        this.recordsIndex = index
      } else {
        this.invoiceList = record.invoice_id_list
        let params = {
          id: this.invoiceList[0],
        }

        try {
          let api = 'preview'
          const res = await this.$http[api](params)
          if (res.status !== 200) return

          const contentType = res.headers['content-type']
          console.log(contentType)
          this.dataType = contentType

          // 重置文件类型标志
          this.isPdf = false
          this.isImage = false

          if (contentType === 'application/pdf') {
            console.log('PDF file detected')
            this.isPdf = true
          } else if (['image/png', 'image/jpeg'].includes(contentType)) {
            console.log('Image file detected')
            this.isImage = true
          } else {
            ElMessage.warning('不支持的文件类型')
            return
          }

          // 创建一个 Blob URL 用于文件预览
          const blob = new Blob([res.data], { type: contentType })
          this.fileUrl = URL.createObjectURL(blob)

          this.isShowPreview = true
        } catch (error) {
          console.error('Error fetching file:', error)
          ElMessage.error('获取文件失败')
        } finally {
          loadingInstance.close()
        }
      }
    },
    async printFile() {
      if (this.isPdf) {
        try {
          const loadingTask = getDocument({
            url: this.fileUrl,
            cMapUrl: '/cmaps/',
            cMapPacked: true,
            fontExtraProperties: true,
            standardFontDataUrl: '/cmaps/AdobeKaitiStd-Regular.otf',
          })
          const pdf = await loadingTask.promise

          // 创建一个 iframe 元素
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          document.body.appendChild(iframe)

          const iframeDoc =
            iframe.contentDocument || iframe.contentWindow.document
          iframeDoc.write(
            '<html><head><title>打印预览</title></head><body></body></html>'
          )

          // 创建一个容器来存放所有的页面
          const container = iframeDoc.createElement('div')
          iframeDoc.body.appendChild(container)

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i)
            const scale = 2 // 增加分辨率以提高打印质量
            const viewport = page.getViewport({ scale: scale })

            const canvas = iframeDoc.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.height = viewport.height
            canvas.width = viewport.width

            const pageContainer = iframeDoc.createElement('div')
            pageContainer.style.pageBreakAfter = 'always'
            pageContainer.appendChild(canvas)
            container.appendChild(pageContainer)
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            }
            await page.render(renderContext).promise
          }
          // 添加打印样式
          const style = iframeDoc.createElement('style')
          style.textContent = `
        @page { size: auto; margin: 0; }
        body { margin: 0; }
        canvas { display: block; margin: auto; page-break-after: always; }
      `
          iframeDoc.head.appendChild(style)
          iframeDoc.close()
          // 等待内容加载完成后打印
          setTimeout(() => {
            iframe.contentWindow.print()
            setTimeout(() => {
              document.body.removeChild(iframe)
            }, 1000)
          }, 1000)
        } catch (error) {
          console.error('PDF打印出错:', error)
          this.$message.error('PDF打印失败，请重试')
        }
      } else if (this.isImage) {
        // 使用 print-js 打印图片
        printJS({
          printable: this.fileUrl,
          type: 'image',
          header: '发票图片', // 可选：添加标题
          imageStyle: 'width:100%;', // 可选：设置图片样式
        })
      }
    },

    renderPDF(pdfData) {
      this.$nextTick(() => {
        const canvas = document.getElementById('invoices-preview')
        const loadingTask = getDocument({
          data: pdfData,
          cMapUrl: '/cmaps/',
        })
        loadingTask.promise
          .then((pdfDocument) => {
            const pageNumber = 1 // 第一页
            pdfDocument.getPage(pageNumber).then((page) => {
              const scale = 1
              const viewport = page.getViewport({ scale })
              const context = canvas.getContext('2d', {
                willReadFrequently: true,
              })
              canvas.height = viewport.height
              canvas.width = viewport.width

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
                renderText: true,
              }
              page.render(renderContext)
            })
          })
          .catch((error) => {
            console.error('PDF加载失败：', error)
          })
      })
    },
    async ConvertPDF(pdfData) {
      try {
        const pdf = await getDocument({ data: pdfData, cMapUrl: '/cmaps/' })
          .promise
        const pageCount = pdf.numPages

        let images = []
        for (let i = 1; i <= pageCount; i++) {
          const page = await pdf.getPage(i)
          const scale = 5.0 // 增加缩放比例，提高分辨率
          const viewport = page.getViewport({ scale: scale })
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          }

          await page.render(renderContext).promise

          // 使用更高质量的图片格式
          const imageData = canvas.toDataURL('image/png', 1.0)
          images.push(imageData)
        }

        this.images = images
      } catch (error) {
        console.error('Error fetching or converting PDF:', error)
      }
    },
    printInvoice() {
      const element = document.getElementById('invoices-preview')
      if (!element) {
        console.error('Element not found')
        return
      }

      let printable
      if (element instanceof HTMLCanvasElement) {
        // 如果是 Canvas 元素，将其转换为图片
        printable = element.toDataURL('image/png')
      } else if (element instanceof HTMLImageElement) {
        // 如果是 img 元素，直接使用其 src 属性作为打印内容
        printable = element.src
      } else {
        console.error('Unsupported element type')
        return
      }

      // 使用 print.js 打印图片
      printJS({
        printable: printable,
        type: 'image',
        dpi: 300,
        // header: '发票', // 打印文件标题
        // documentTitle: '付款回传发票', // 可选的打印页眉
      })
    },
    confirmInvoice() {
      const loadingInstance = ElLoading.service()

      const invoiceIds = []
      let errorEncountered = false

      this.invoiceList.forEach((item) => {
        if (item.response && item.response.code !== 200) {
          errorEncountered = true
        } else if (
          item.response &&
          item.response.data &&
          item.response.data.invoice_id
        ) {
          invoiceIds.push(item.response.data.invoice_id)
        }
      })

      if (errorEncountered || !this.invoiceList) {
        loadingInstance.close()
        this.showModal('error', '请检查发票')
        return
      }

      let api = 'payment_confirm'
      let params = {
        approval_id: this.approvalId,
        invoice_id_list: invoiceIds,
      }

      this.$http[api](params).then((res) => {
        loadingInstance.close()

        if (res.status != 200) return
        else if (res.data.code != 200)
          this.$message({
            type: 'error',
            message: res.data.msg,
            showClose: true,
          })
        else {
          this.records[this.recordsIndex].need_invoice = 0
          this.dialogVisible = false
          this.approvalId = ''
          this.recordsIndex = ''
          this.$message({
            type: 'success',
            message: '回传成功',
            showClose: true,
          })
        }
      })
    },
  },
  mounted() {
    this.initWechatSDK()
    this.getApprove()
    this.initData()
  },
  computed: {
    filteredRecords() {
      return this.records.filter((record) => {
        const approvalFilter =
          this.selectedApprovalStatus === 'all' ||
          record.approvalStatus === this.selectedApprovalStatus
        const invoiceFilter =
          this.selectedInvoiceStatus === 'all' ||
          record.invoiceStatus === this.selectedInvoiceStatus
        return approvalFilter && invoiceFilter
      })
    },
    // isPdf() {
    //   return this.dataType === 'application/pdf'
    // },
    // isImage() {
    //   return ['image/png', 'image/jpeg'].includes(this.dataType)
    // },
    disablePrint() {
      // 添加禁用打印按钮的逻辑
      // 例如，当没有数据可打印时禁用打印按钮
      return !this.isPdf && !this.isImage
    },
    dialogWidth() {
      // 获取窗口宽度
      const windowWidth = window.innerWidth
      console.log(windowWidth)
      // 如果屏幕宽度小于等于手机尺寸，返回100%，否则返回50%
      return windowWidth <= 768 ? '100%' : '70%'
    },
    canPrint() {
      return this.isPdf || this.isImage
    },
    pdfViewerUrl() {
      if (this.isPdf) {
        // 添加参数来隐藏头部选项和侧边栏
        return `${this.fileUrl}#toolbar=0&navpanes=0&scrollbar=0`
      }
      return this.fileUrl
    },
  },
  watch: {
    selectedFunction(newValue, oldValue) {
      console.log('Function changed from', oldValue, 'to', newValue)
      if (newValue === 'uploadInvoice') {
        this.selectedApprovalStatus = '2'
        this.selectedInvoiceStatus = '1'
      }
    },
  },
}
</script>

<style scoped>
.page-container {
  max-width: 520px;
  margin: 0 auto;
  /* display: flex; */
  /* flex-direction: column; */
  position: relative;
  min-height: calc(
    100vh - 58px
  ); /* 计算剩余高度，确保内容撑满整个视口减去底部区域高度 */
}

.pdf-container {
  width: 100%;
  height: calc(70vh - 20px);
  overflow: auto;
}

.image-container {
  width: 100%;
  height: calc(70vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-card,
.detail-card {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.card-header {
  font-size: 12px;
  text-align: left;
}

.content {
  background-color: #f5f5f5;
  padding: 0 5px;
  min-height: calc(100vh - 58px);
  padding-bottom: calc(58px + 15px); /* 让出底部区域高度 */
  /* margin-bottom: -58px; 通过负的 margin-bottom 来抵消底部区域的空白，实现阴影效果延伸 */
}
/* :where(
    .css-dev-only-do-not-override-11vqlyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-11vqlyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
} */

/* @media (max-width: 575px)
:where(.css-dev-only-do-not-override-1qb1s0s).ant-form .ant-form-item {
    flex-wrap: wrap;
} */
.payment-form {
  background-color: #f5f5f5;
  /* background-color: #ffffff; */
  /* padding: 10px; */
  /* border-radius: 8px; */
}

.expense-baseinfo {
  padding: 10px;
  border-radius: 8px;
}

/* .expense-header { */
/* display: flex; */
/* justify-content: space-between; */
/* align-items: baseline; */
/* background-color: #f5f5f5; 添加背景色 */
/* padding: 10px; 添加内边距 */
/* margin-left: -10px; */
/* margin-right: -10px; */
/* } */

.expense-title {
  font-size: 12px;
}

.expense-detail-container {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.expense-header {
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expense-detail {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.expense-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  background-color: #f5f5f5;
  padding: 10px;
}

.expense-detail-title {
  font-size: 12px;
}

.expense-divider {
  font-size: 12px; /* 调整字体大小 */
  border-block-start: 0 rgb(5, 5, 5, 0.1);
}

.add-detail-btn {
  margin-top: 10px;
}

.footer {
  /* margin-top: 20px; */
  /* text-align: center; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px; /* 设置底部区域高度 */
  background-color: #fff;
  box-shadow: 0px -5px 5px -5px #8f8f8f; /* 添加底部阴影效果，可根据需要调整 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  justify-content: center;
  gap: 12px; /* 两个按钮之间的间隔 */
}

.footer-container button {
  margin-right: 12px;
}

/* 修改 .ant-card-meta-avatar 的 padding-inline-end 样式 */
:deep(.ant-card-meta-avatar) {
  padding-inline-end: 5px;
}

/* 取消 .ant-card-actions 的 border-top 样式 */
:deep(.ant-card-actions) {
  border-top: none;
}

.custom-plus-btn {
  color: #267ef0;
  background-color: #dcebfd;
  padding: 15px;
  border-radius: 15%;
  cursor: pointer;
}

/* :deep(.ant-timeline-item) { */
/* display: flex; */
/* flex-direction: column; 垂直布局，内容在头部下面 */
/* } */

/* :deep(.ant-timeline-item-head), */
/* :deep(.ant-timeline-item-content) { */
/* flex: 1; 将头部和内容平均占据可用空间 */
/* } */

/* TODO 未实现 禁用上传列表删除按钮悬浮触发 */
/* 显示删除按钮 */
/* .ant-upload-list-item:hover .ant-upload-list-item-actions {
  opacity: 1;
}
:deep(.ant-upload-list-item:hover) {
  opacity: 1;
} */
.selected-button {
  background-color: #1890ff; /* 选中时的背景颜色 */
  color: #fff; /* 选中时的文本颜色 */
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 58px - 15px);
}

.button-container button {
  display: block;
  width: 80%;
  margin-bottom: 20px;
}

.filter-container {
  position: sticky;
  top: 0;
  height: 100%;
  background-color: white;
  padding-top: 5px;
  z-index: 1;
  /* margin-top: -10px; */
}

.filter-divider {
  margin-top: 5px;
  margin-bottom: 0px;
}
.full-height-card {
  text-align: left;
  height: 100%;
  width: 100%;
}
.payment-name {
  font-weight: bold;
  margin-bottom: 8px; /* 添加间距 */
}

.payment-info p {
  margin-bottom: 1px; /* 添加间距 */
  font-size: 14px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号显示 */
}

.card-descriptions {
  /* margin-top: -10px; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  .el-descriptions__header {
    margin-bottom: 5px;
  }
}
:deep(.el-descriptions__header) {
  margin-top: -10px;
  margin-bottom: 5px;
}
:deep(
    .el-descriptions__body
      .el-descriptions__table.is-bordered
      .el-descriptions__cell
  ) {
  padding: 5px 11px;
}
</style>
