import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

let development = process.env.NODE_ENV == 'development' ? true : false

export default createStore({
  state: {
    development: development,
    isShowLogin: !localStorage.getItem('accessToken'),
    isShowTable: false,
    isShowUpload: false,
    isShowDownload: false,
    isShowRecord: false,
    isHasDialog: false,
    searchKeyword: '',
    searchButton: 0,
    baseUrl: development
      ? 'http://invoices-test.zhrhkj.com/api'
      : 'https://invoices.zhrhkj.com/api',
    loaclUrl: development
      ? 'http://invoices-test.zhrhkj.com'
      : 'https://invoices.zhrhkj.com',
    wxUrl: 'https://qyapi.weixin.qq.com/cgi-bin/user/getuserinfo/',
    accessToken: localStorage.getItem('accessToken') || null,
    corpId: development ? 'ww701da2fa3f729b79' : 'wwca551919313eb542',
    agentId: development ? '1000018' : '1000040',
    targetName: localStorage.getItem('targetName') || 'home',
  },
  getters: {
    getToken(state) {},
  },
  mutations: {
    saveToken(state, token) {
      localStorage.setItem('accessToken', token)
      state.accessToken = token
    },
    removeToken(state) {
      localStorage.removeItem('accessToken')
      state.accessToken = null
      state.isShowLogin = true
    },
    changeTarget(state, name) {
      state.targetName = name
      localStorage.setItem('targetName', name)
    },
    updateIsHasDialog(state, value) {
      state.isHasDialog = value
    },
  },
  actions: {},
  modules: {},
  plugins: [],
})
