let unit = (val) => {
  if (typeof val == 'string') {
    return val
  } else {
    return val + 'px'
  }
}
export default {
  // focus
  focus: {
    inserted: function (el, binding) {
      if (binding.value) {
        // 聚焦元素
        el.focus()
      }
    },
  }, //end
  // size
  size: {
    inserted: function (el, binding) {
      el.style.height = unit(binding.value)
      el.style.width = unit(binding.value)
    },
    update: function (el, binding) {
      el.style.height = unit(binding.value)
      el.style.width = unit(binding.value)
    },
  }, //end
}
