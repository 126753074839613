<template>
  <div class="app-box light">
    <div class="app-bg" :style="'filter: blur(0px);'">
      <!-- <video
        class="app-bg-video"
        autoplay="true"
        loop="true"
        muted="true"
        playsinline
        controls="false"
        x5-video-player-fullscreen="true"
        x5-playsinline
        preload="auto"
        webkit-playsinline
        :src="wallpaperSrc"
        ref="video"
      ></video> -->
      <video-background 
        :src="wallpaperSrc"
        ref="videobackground"
        class="app-bg-video"
      >
        <!-- <h1 style="color: white;">Hello welcome!</h1> -->
      </video-background>
    </div>
    <div class="app-container" v-if="!$store.state.isShowLogin">
      <div class="app-cover">
        <div class="app-header">
          <HeaderBox />
        </div>
        <div :style="{ height: getHeight('search') }">
          <SearchView @showTable="upBox" />
        </div>
        <div><UploadView /></div>
        <div :style="{ height: getHeight('table') }">
          <TableView />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, watch } from 'vue'

import SearchView from './SearchView.vue'
import TableView from './TableView.vue'
import UploadView from './UploadView.vue'
import HeaderBox from '@/components/header'

export default {
  name: 'HomeView',
  data() {
    return {
      wallpaper: {
        title: '格里姆瑟尔山口Totesee山地湖中倒映出的星星',
        local: '欧洲 瑞士 伯尔尼',
        src: 'https://az3.sfo2.cdn.digitaloceanspaces.com/bingdesktop/3455b6778764415593fb5e688218fb89.mp4',
      },
      wallpaperSrc: require('../assets/9kdtl215agivlql571evhoarrwy3mctr.mp4'),
      isShowTable: false,
    }
  },
  props: {},
  components: {
    SearchView,
    HeaderBox,
    TableView,
    UploadView,
  },
  methods: {
    getHeight(type) {
      if (type == 'header') {
        return '0%'
      } else if (type == 'search') {
        if (this.isShowTable) return '10%'
        else return '100%'
      } else if (type == 'table') {
        if (this.isShowTable) return '85%'
        else return '0%'
      }
    },
    upBox() {
      this.isShowTable = true
    },
    initVideo() {
      this.$refs.videobackground.player.play()

      // // 在iOS上，视频需要在用户交互后才能播放
      // document.addEventListener('click', () => {
      //   video
      //     .player.play()
      //     .then(() => {
      //       // 视频开始播放
      //       console.log('play')
      //     })
      //     .catch((error) => {
      //       console.error('Error playing video:', error)
      //     })
      // })
    },
  },
  mounted() {
    console.log(localStorage.getItem('accessToken'))
    console.log(this.$store.state.isShowLogin)
    // var video = document.getElementsByName('app-bg-video')
    // video.play()
    this.initVideo()
  },
  watch: {
    '$store.state.isShowTable': {
      handler(newValue, oldValue) {
        this.isShowTable = newValue
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style lang="less" scoped>
.app-box {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #333;
  .app-bg {
    position: absolute;
    backface-visibility: hidden;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    .app-bg-img,

    // iOS 还是无效
    // 隐藏video 音量按钮
    .app-bg-video::-webkit-media-controls-mute-button,
    // 隐藏video 当前按钮
    .app-bg-video::-webkit-media-controls-current-time-display,
    // 隐藏video 总时间
    .app-bg-video::-webkit-media-controls-time-remaining-display,
    // 隐藏video 播放按钮
    .app-bg-video::-webkit-media-controls-play-button,
    // 隐藏video 暂停播放按钮
    .app-bg-video::-webkit-media-controls-pause-button,
    // 隐藏video 时间线
    .app-bg-video::-webkit-media-controls-timeline,
    // 隐藏video 一般性控件
    .app-bg-video::-webkit-media-controls,
    .app-bg-video::-webkit-media-controls-volume-slider,
    .app-bg-video::-webkit-media-controls-mute-button,
    .app-bg-video::-webkit-media-controls-start-playback-button,
    // 隐藏video 全屏按钮
    .app-bg-video::-webkit-media-controls-fullscreen-button {
      display: none !important;
    }

    .app-bg-video {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
    .app-bg-img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.bgBlur {
      filter: blur(10px);
      transform: scale(1.042);
    }
  }
  .app-cover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: radial-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.2) 166%),
      linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 0% 75%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
  }
}
.wallpaper-info {
  position: absolute;
  transition-duration: 0.2s;
  color: rgba(255, 255, 255, 0.2);

  right: 10%;
  bottom: 10%;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}
// .app-header {
//   height: 0%;
// }

.app-search {
  height: 100%;
  &.active {
    height: 20%;
  }
}

// .app-table {
// }
</style>
