<template>
  <div class="d-flex-all app-search-area">
    <div class="app-serach-box">
      <div class="se-input-box" :class="{ active: value }">
        <div class="se-select" @click.stop="isShowDownload = !isShowDownload">
          <el-icon v-show="isShowDownload"><Download /></el-icon>
          <el-icon v-show="!isShowDownload"><Upload /></el-icon>
        </div>
        <input
          v-focus="true"
          v-model.trim="value"
          class="se-input"
          @input="keyWordList()"
          @keyup.enter="searchHandle(value)"
          @keydown="keydown($event)"
          placeholder="输入发票号码/审批ID搜索"
          type="text"
        />
        <div class="se-close" v-show="value" @click="value = ''">
          <d-icon v-size="20" icon="icon-clost" class="select-icon"></d-icon>
        </div>
        <div class="se-select" @click="searchHandle(value)">
          <el-icon><Search /></el-icon>
          <!-- <d-icon v-size="20" icon="icon-search" class="select-icon"></d-icon> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Search, Download, Upload } from '@element-plus/icons-vue'

export default {
  name: 'SearchBox',
  data() {
    return {
      value: '', //input绑定值
      isShowDownload: true,
      currSearch: {},
      searchList: [
        {
          key: 'baidu',
          title: '百度搜索',
          icon: 'baidu',
          href: 'http://www.baidu.com/s?wd=',
          translate: 'https://fanyi.baidu.com/translate?#zh/en/',
        },
        {
          key: 'bing',
          title: '必应搜索',
          icon: 'bing',
          href: 'https://cn.bing.com/search?q=',
          translate: 'https://fanyi.baidu.com/translate?#zh/en/',
        },
        {
          key: 'google',
          title: 'google搜索',
          icon: 'google',
          href: 'https://www.google.com/search?q=',
          translate:
            'https://translate.google.cn/#view=home&op=translate&sl=en&tl=zh-CN&text=',
        },
      ],
    }
  },
  emits: ['showTable'],
  methods: {
    keyWordList() {},
    searchHandle(value, type) {
      if (!this.value) {
        // this.$message({
        //   type: 'error',
        //   message: `未填写搜索词`,
        //   showClose: true,
        // })
        this.$store.state.isShowTable = true
        this.$store.state.searchButton = !this.$store.state.searchButton
        this.$store.state.searchKeyword = this.value
      } else {
        // this.isShowTable = true
        // this.$emit('showTable')

        this.$store.state.isShowTable = true
        this.$store.state.searchButton = !this.$store.state.searchButton
        this.$store.state.searchKeyword = this.value
      }
    },
    keydown(el) {
      console.log(el)
    },
  },

  props: {},
  components: {
    Search,
    Download,
    Upload,
  },
}
</script>

<style lang="less" scoped>
.app-search-area {
  height: 90%;
  margin: -20px 0px 20px 0px;
}
.app-serach-box {
  position: relative;
  text-align: center;
  padding: 0 20px;
  width: 600px;
  .se-input-box {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    backdrop-filter: blur(8px);
    background-color: rgba(var(--background), 0.4);
    overflow: hidden;
    display: flex;
    height: 54px;
    align-items: center;
    border-radius: 40px;
    transition: 0.2s;
    color: rgba(var(--main-color), 0.8);
    .select-icon {
      color: rgba(var(--main-color), 0.3);
    }
    .se-select {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-width: 50px;
      max-width: 50px;
      transition: 0.2s;

      &:hover {
        background-color: rgba(var(--background), 0.2);
      }
    }
    .se-input {
      color: rgba(var(--main-color), 0.94);
      background-color: transparent;
      font-size: 14px;
      width: 100%;
    }
    &:hover,
    &.active {
      background-color: rgba(var(--background), 0.8);
    }
  }
  .se-list-keyword {
    position: absolute;
    padding: 0 20px;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .se-all {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 0;
    transition: 0.2s;
    // backdrop-filter: blur(8px);
    width: 100%;
    border-radius: 30px;
    padding: 5px 10px;
    // box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4);

    font-size: 0;
    .se-item {
      color: #eee;
      .se-item-icon {
        color: rgba(var(--main-color), 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        line-height: 30px;
        background-color: rgba(var(--background), 0.1);
        margin: 0 auto;
        height: 30px;
        width: 80%;
        border-radius: 15px;
        backdrop-filter: blur(8px);
        transition: 0.2s;
        transform: translateY(-20px);
        &:hover {
          background-color: rgba(var(--background), 0.6);
        }
        &.action {
          background-color: rgba(var(--background), 0.6);
        }
      }

      display: inline-block;
      width: 20%;
    }
    &.action {
      padding: 10px;
      height: 50px;
      transform: scaleY(1);
      .se-item {
        .se-item-icon {
          opacity: 1;
          vertical-align: middle;
          transform: rotateX(0);
        }
      }
    }
  }

  //   关键字列表
  .se-keyword {
    backdrop-filter: blur(10px);
    transition: 0.2s;
    height: 0;
    background-color: rgba(var(--background), 0.3);
    border-radius: 20px;
    overflow: hidden;
    .se-keyword-item {
      cursor: pointer;
      padding: 0 30px;
      color: rgba(var(--main-color), 0.8);
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      transition: 0.2s;
      &.focus,
      &:hover {
        background-color: rgba(var(--background), 0.2);
        text-indent: 10px;
      }
      &:hover {
        text-indent: 10px;
      }
    }
    &.active {
      height: 30px;
    }
  }
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(var(--main-color), 0.8);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(var(--main-color), 0.8);
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(var(--main-color), 0.8);
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(var(--main-color), 0.8);
}
</style>
