<template>
  <div class="app-content">
    <UploadBox />
  </div>
</template>

<script>
import UploadBox from '@/components/UploadBox.vue'

export default {
  name: 'UploadView',
  components: {
    UploadBox,
  },
}
</script>
<style lang="less" scoped>
.app-container {
  // position: absolute;
  height: 100%;
  width: 100%;
}
.app-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>
