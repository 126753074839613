<template>
  <!-- 搜索框功能 -->
  <searchBox />
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'

export default {
  name: 'SearchView',
  methods: {},
  components: {
    SearchBox,
  },
}
</script>
<style lang="less" scoped></style>
